import Utils from '../utils/Utils';
import ENV from './Environment';
import LocalStorageService from './LocalStorageService';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1/auth/authenticate`;
const baseUserUrl = `${ENV.base_url}/api/v1/users`

const loginUser = async (username, password, keepMeSignedIn, stateToUpdate) => {
  const result = await unsecureFetchRequest( {username, password, keepMeSignedIn });
  const data = await result.clone().json();
  if (!result.ok) {
    stateToUpdate.postError = data.error;
    throw new Error(stateToUpdate.error);
  }
  updateUserFromExternalData(stateToUpdate, data);
  LocalStorageService.saveEncryptedData(data);
  stateToUpdate.postError = null;
  return data;
}

const getUsersWithLowerCaseRoles = (userList) => {
  const userListWithLowerCaseRoles = [];
  userList.forEach(user => {
    const roles = user.roles.map(role => role.toLowerCase());
    userListWithLowerCaseRoles.push({
      ...user,
      roles: roles
    });
  });
  return userListWithLowerCaseRoles;
}

const getUsers = async (userData, activePage, searchText) => {
  const userListUrl = `${baseUserUrl}/list?page=${activePage - 1}&searchText=${Utils.sanitizeText(searchText)}`;
  const result = await Requests.getRequest(userListUrl, userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(Utils.getErrorFromRequest(data));
  }
  data.content = getUsersWithLowerCaseRoles(data.content);
  return data;
}

const logOutUser = (userData) => {
  userData.jwtToken = null;
  localStorage.removeItem(ENV.storageKey);
}

const checkUserLoggedIn = (userData) => {
  if (userData.jwtToken !== null) {
    return true;
  }
  const localStorageData = LocalStorageService.getEncryptedLocalStorageData();
  if (localStorageData === null) {
    return false;
  }
  if (localStorageData.token === null) {
    logOutUser(userData);
    return false;
  }
  updateUserFromExternalData(userData, localStorageData);
  return true;
}

const updateUserFromExternalData = (userData, externalData) => {
  userData.jwtToken = externalData.token;
  userData.username = externalData.username;
  userData.refreshToken = externalData.refreshToken;
  if (!Utils.isNull(externalData.roles)) {
    userData.roles = externalData.roles;
  } else {
    userData.roles = [ externalData.role ];
  }
}

const unsecureFetchRequest= async (payLoad) => {
  return await fetch(baseUrl, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payLoad)
  });
}

const UserService = {
  loginUser: loginUser,
  checkUserLoggedIn: checkUserLoggedIn,
  logOutUser: logOutUser,
  getUsers
}

export default UserService;