import { Route, Routes } from "react-router-dom"
import VersionControl from "./VersionControl/VersionControl"
import NotFound from "../../NotFound/NotFound";
import VersionHistory from "./VersionHistory/VersionHistory";

const SettingsMain = () => {
    return (
        <Routes>
            <Route path="version-control" Component={VersionControl} />
            <Route path="version-history" Component={VersionHistory} />
            <Route path="*" Component={NotFound} />
        </Routes>
    )
}

export default SettingsMain;