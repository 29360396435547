import { useContext, useState, useEffect, useCallback } from 'react';
import NewAssetStyle from './NewAsset.module.css';
import DropZoneDiv from './DropZoneDiv/DropZoneDiv';
import AssetDetails from './AssetDetails/AssetDetails';
import UploadService from '../../services/UploadService';
import UserData from '../../services/UserData';
import GeneratorService from '../../services/GeneratorService';
import ButtonView from './ButtonView/ButtonView';
import DownloadService from '../../services/DownloadService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Utils from '../../utils/Utils';
import NotFound from '../NotFound/NotFound';

const NewAsset = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	const assetKey = params['assetKey'];
	const assetType = params['assetType'];

	const loadingHeadingText = Utils.isNull(assetKey)
		? 'New Asset'
		: 'Edit Asset';

	const guidFromParam = Utils.isNull(assetKey) ? null : assetKey;
	const userData = useContext(UserData);
	const [filesUploaded, updateFilesUploaded] = useState([]);
	const [headingText, updateHeadingText] = useState(loadingHeadingText);
	const [actionItemsRequired, updateActionItems] = useState([]);
	const [generatingLogs, updateGeneratingLogs] = useState([]);
	const [guid, updateJobGuid] = useState(guidFromParam);
	const [ready, updatePluginReadyStatus] = useState(false);
	const [generating, updateGenerating] = useState(false);
	const [uploadingInProgress, updateUploading] = useState(false);
	const [downloadFiles, updateDownloadFiles] = useState({
		interactiveFiles: null,
		assessmentFiles: null,
		mediaFiles: null,
	});
	const [downloadReady, updateDownloadReady] = useState(false);
	const [downloading, updateDownloading] = useState(false);
	const [failedJob, updateFailedJob] = useState(false);

	const backButtonClick = () => {
		if (Utils.isNull(location.state) || Utils.isNull(location.state.referrer)) {
			return;
		}
		navigate(location.state.referrer);
	};

	const uploadFileOrFilesToServer = (acceptedFiles) => {
		UploadService.uploadFiles(acceptedFiles, guid, userData.jwtToken)
			.then((data) => {
				updatePageAfterfilesUploaded(acceptedFiles, data);
			})
			.catch((error) => {
				console.error(error);
				showServerError(error.message);
			});
		updateUploading(true);
	};

	const getServerErrors = (data) => {
		if (data.jobStatus === 'REQUIRE_SPREADSHEET' && data.errors.length === 0) {
			return ['Spreadsheet required.'];
		}
		if (data.errors.length > 0) {
			return data.errors;
		}
		return [];
	};

	const showUserData = useCallback((files, data) => {
		const serverErrors = getServerErrors(data);

		updateFilesUploaded(data.uploadedFiles);
		updatePluginReadyStatus(false);
		updateActionItems(serverErrors);
		updateJobGuid(data.guid);
		updateUploading(false);
	}, []);

	const showReadyStatus = useCallback(
		(files, data) => {
			const serverStatus = ['Ready to generate'];

			updateFilesUploaded(data.uploadedFiles);
			updateActionItems(serverStatus);
			updatePluginReadyStatus(true);
			updateUploading(false);
			updateJobGuid(data.guid);
			updateHeadingText(headingText);
		},
		[headingText]
	);

	const handleFileUploadResponse = useCallback(
		(files, data) => {
			if (
				data.jobStatus === 'REQUIRE_FILE_CHECK' ||
				data.jobStatus === 'REQUIRE_SPREADSHEET'
			) {
				showUserData(files, data);
			}
			if (data.jobStatus === 'READY' || data.jobStatus === 'FINISHED') {
				showReadyStatus(files, data);
			}
		},
		[showUserData, showReadyStatus]
	);

	const updatePageAfterfilesUploaded = (files, data) => {
		if (data.jobStatus === 'REQUIRE_FILE_CHECK') {
			checkJobStatus(files, data);
			return;
		}
		handleFileUploadResponse(files, data);
	};

	const checkJobStatus = useCallback(
		(files, data) => {
			GeneratorService.checkJob(data.guid, userData.jwtToken)
				.then((checkJobData) => {
					handleFileUploadResponse(files, checkJobData);
				})
				.catch((error) => {
					showServerError(error.message);
				});
		},
		[userData, handleFileUploadResponse]
	);

	const showServerError = (error) => {
		const showError = error;
		updateActionItems([showError]);
		updateUploading(false);
	};

	const generatePlugin = () => {
		startJob(guid);
		updateGeneratingLogs(['Preparing to generate']);
		updatePluginReadyStatus(false);
		updateGenerating(true);
	};

	const startJob = (guid) => {
		GeneratorService.startJob(guid, userData.jwtToken)
			.then((data) => {
				manageJobPoll(data);
			})
			.catch((error) => {
				updateFailedJob(true);
				updateGeneratingLogs([error.message]);
			});
	};

	const checkJobGeneratingStatus = (data) => {
		GeneratorService.checkJob(data.guid, userData.jwtToken)
			.then((data) => {
				manageJobPoll(data);
			})
			.catch((error) => {
				updateFailedJob(true);
				updateGeneratingLogs([error.message]);
			});
	};

	const manageJobPoll = (data) => {
		if (
			data.jobStatus === 'BUSY' ||
			data.jobStatus === 'STARTED' ||
			data.jobStatus === 'UPLOADING_BACKUP'
		) {
			setTimeout(() => checkJobGeneratingStatus(data), 200);
			notifyUserOfJob(data);
		}
		if (data.jobStatus === 'FINISHED') {
			setTimeout(() => {
				previewAsset(data);
			}, 1000);
			updateDownloadFiles({
				interactiveFiles: data.interactiveDownloadPaths,
				assessmentFiles: data.assessmentDownloadPaths,
				mediaFiles: data.mediaDownloadPaths,
			});
			updateDownloadReady(true);
			updateGeneratingLogs(data.messages.reverse());
		}
		if (data.jobStatus === 'FAILED') {
			updateFailedJob(true);
			updateGeneratingLogs(data.messages.reverse());
		}
	};

	const notifyUserOfJob = (data) => {
		updateGeneratingLogs(data.messages.reverse());
	};

	const downloadInteractivePlugin = (filename) => {
		downloadFile(filename, 'interactive');
	};

	const downloadMediaPlugin = (filename) => {
		downloadFile(filename, 'audio');
	};

	const downloadAssessmentPlugin = (filename) => {
		downloadFile(filename, 'assessment');
	};

	const downloadFile = (filename, downloadType) => {
		DownloadService.downloadFile(
			guid,
			userData.jwtToken,
			filename,
			`${filename}.zip`,
			downloadType
		)
			.then((data) => {
				updateDownloading(false);
			})
			.catch((error) => {
				console.error(error);
				updateDownloading(false);
			});
		updateDownloading(true);
	};

	const previewAsset = (data) => {
		const pathStart = assetType === 'audio' ? '/audio' : '/plugins';
		navigate(`${pathStart}/asset/${assetType}/${data.guid}`, {
			state: { referrer: `${pathStart}`, jobDetails: data },
		});
	};

	const buttonActions = {
		generatePlugin: generatePlugin,
		downloadInteractivePlugin: downloadInteractivePlugin,
		downloadAssessmentPlugin: downloadAssessmentPlugin,
		downloadMediaPlugin: downloadMediaPlugin,
		previewAsset: previewAsset,
		updateView: backButtonClick,
	};

	useEffect(() => {
		if (Utils.isNull(assetKey)) {
			return;
		}
		checkJobStatus(null, { guid: assetKey });
		updateUploading(true);
	}, [assetKey, checkJobStatus]);

	const buttonsActiveStatus = {
		generateDisabled: !ready,
		downloadDisabled: !downloadReady,
		downloading: downloading,
	};

	const buttonsData = {
		downloadFiles: downloadFiles,
	};

	const goBack = () => {
		backButtonClick();
	};

	const generateType = assetType === 'audio' ? 'AUDIO' : 'PLUGIN';

	const getGeneratingView = () => {
		return (
			<div className={NewAssetStyle.new_plugin}>
				<div className={NewAssetStyle.heading}>
					<FontAwesomeIcon
						className={NewAssetStyle.back_button}
						icon={faCircleChevronLeft}
						onClick={goBack}
					/>
					<span>{headingText}</span>
				</div>
				<DropZoneDiv
					uploadFileOrFilesToServer={uploadFileOrFilesToServer}
					uploadingInProgress={uploadingInProgress}
					generating={generating}
					downloadReady={downloadReady}
					failedJob={failedJob}
					filesUploaded={filesUploaded}
				/>
				<AssetDetails
					filesUploaded={filesUploaded}
					actionItemsRequired={actionItemsRequired}
					generating={generating}
					generatingLogs={generatingLogs}
				/>
				<ButtonView
					buttonsActiveStatus={buttonsActiveStatus}
					buttonActions={buttonActions}
					buttonsData={buttonsData}
					generateType={generateType}
				/>
			</div>
		);
	};

	const getUserView = () => {
		if (Utils.userIsViewOnly(userData)) {
			return <NotFound />;
		}

		return getGeneratingView();
	};

	return getUserView();
};

export default NewAsset;
