import Utils from "../utils/Utils";
import ENV from "./Environment";
import Requests from "./Requests";

const baseUrl = `${ENV.base_url}/api/v1/ai_essay`;

const allowedRoles = [
    'admin',
    'ai_essay_admin',
    'ai_essay_view',
    'ai_essay_create'
];

const createRoles = [
    'admin',
    'ai_essay_admin',
    'ai_essay_create'
]

const getMarking = async (userData, guid) => {
    const url = `${baseUrl}/mark/${guid}`;
    const response = await Requests.getRequest(url, userData.jwtToken, userData);
    const data = await response.clone().json();
    if (!response.ok) {
        throw new Error(Utils.getErrorFromRequest(data));
    }
    return data;
}

const sendForMarking = async (userData, postData) => {
    const url = `${baseUrl}/generate`;
    const response = await Requests.postRequest(url, userData.jwtToken, postData, userData);
    const data = await response.clone().json();
    if (!response.ok) {
        throw new Error(Utils.getErrorFromRequest(data));
    }
    return data;
}

const fetchEssays = async (userData, searchText, page) => {
    const url = `${baseUrl}/list?searchText=${searchText}&page=${page}`;
    const response = await Requests.getRequest(url, userData.jwtToken, userData);
    const data = await response.clone().json();
    if (!response.ok) {
        throw new Error(Utils.getErrorFromRequest(data));
    }
    return data;
}

const fetchRubrics = async(userData, searchText) => {
    const url = `${baseUrl}/rubrics`;
    const response = await Requests.getRequest(url, userData.jwtToken, userData);
    const data = await response.clone().json();
    if (!response.ok) {
        throw new Error(Utils.getErrorFromRequest(data));
    }
    return data;
}

const AIEssayService = {
    allowedRoles,
    createRoles,
    fetchEssays,
    fetchRubrics,
    sendForMarking,
    getMarking
}

export default AIEssayService;