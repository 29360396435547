import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./MainContainerWithBackButtonAndActionIcon.module.css";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";

const MainContainerWithBackButtonAndSaveButton = ({children, heading, backButtonAction, icon}) => {
  return (
    <div className={style.children_container}>
      <div className={style.heading}>
        <FontAwesomeIcon className={style.back_button} icon={faCircleChevronLeft} onClick={backButtonAction} />
        <span>{heading}</span>
        {icon}
      </div>
      {children}
    </div>
  )
}

export default MainContainerWithBackButtonAndSaveButton;