import style from './GeneratedAssetView.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import DownloadService from '../../../../services/DownloadService';
import UserData from '../../../../services/UserData';
import { useCallback, useContext, useEffect, useState } from 'react';
import LoadingPulse from '../../../../shared_components/LoadingPulse/LoadingPulse';
import AudioService from '../../../../services/AudioService';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Utils from '../../../../utils/Utils';

const GeneratedAssetView = ({
    children,
    heading,
    url,
    assetKey,
    audioPlaying,
    updateAudioPlaying,
    generatingPreview,
    updateGeneratingPreview
}) => {
  const navigate = useNavigate();
  const userData = useContext(UserData);
  const [downloading, updateDownloading] = useState(false);
  const [audio, updatePlayingAudio] = useState(null);

  const textStyle = !heading || heading === null ? style.normal : style.heading;

  const download = (extention) => {
    DownloadService.downloadFile(assetKey, userData.jwtToken, children, `${children}${extention}`, url).then(data => {
      updateDownloading(false);
    }).catch(error => {
      console.error(error);
      updateDownloading(false);
    })
    updateDownloading(true);
  }

  const handleAudioEnd = useCallback(() => {
    audio.removeEventListener('ended', handleAudioEnd);
    updatePlayingAudio(null);
    updateDownloading(false);
    updateAudioPlaying(false);
  }, [audio, updatePlayingAudio, updateDownloading, updateAudioPlaying]);

  useEffect(() => {
    if (Utils.isNull(audio)) {
      return;
    }
    audio.addEventListener('ended', handleAudioEnd)
    audio.play().then(() => {
    }).catch(error => {
      updateDownloading(false);
      updateAudioPlaying(false);
    })
    return () => {
      if (!Utils.isNull(audio)) {        
        audio.pause();
        updatePlayingAudio(null);
      }
    }
  }, [audio, handleAudioEnd, updateDownloading, updateAudioPlaying]);

  const playAudio = () => {
    if (audioPlaying) {
      return;
    }
    AudioService.getAudioBlob(assetKey, userData.jwtToken, children, userData).then(blob => {
      const tempAudio = new Audio(blob);
      const blobUrl = URL.createObjectURL(blob);
      tempAudio.src = blobUrl;
      updatePlayingAudio(tempAudio);
    }).catch(error => {
      console.error(error);
      updateDownloading(false);
      updateAudioPlaying(false);
    })
    updateDownloading(true);
    updateAudioPlaying(true);
  }

  const downloadMediaView = () => {
    if (Utils.userIsViewOnly(userData)) {
      return null;
    }

    return (
      <OverlayTrigger placement="left" overlay={<Tooltip id={`download-${children}`}>Download</Tooltip>}>
        <FontAwesomeIcon onClick={() => download(".wav")} icon={faDownload} />
      </OverlayTrigger>
    );
  }

  const getForMedia = () => {
    if (downloading) {
      return <LoadingPulse />
    }
    return (
      <div className={style.icon}>
        <OverlayTrigger placement="left" overlay={<Tooltip id={children}>Play Audio</Tooltip>}>
          <FontAwesomeIcon onClick={playAudio} icon={faVolumeHigh} />
        </OverlayTrigger>
        {downloadMediaView()}
      </div>
    )
  }

  const navigateToViewPage = () => {
    navigate(`/plugins/view/${url}/${assetKey}/${children}`, { state: { referrer: `/plugins/asset/interactive/${assetKey}` }});
  }

  const previewAsset = () => {
    if (generatingPreview) {
      return;
    }
    navigateToViewPage();
    updateGeneratingPreview(true);
    updateDownloading(true);
  }

  const downloadZipView = () => {
    if (Utils.userIsViewOnly(userData)) {
      return null;
    }

    return (
      <OverlayTrigger placement="left" overlay={<Tooltip id={`download-${children}`}>Download</Tooltip>}>
          <FontAwesomeIcon onClick={() => download(".zip")} icon={faDownload} />
      </OverlayTrigger>
    )
  }

  const getForZip = () => {
    if (downloading) {
      return <LoadingPulse />
    }

    return (
      <div className={style.icon}>
        <OverlayTrigger placement="left" overlay={<Tooltip id={`preview-${children}`}>Preview</Tooltip>}>
          <FontAwesomeIcon onClick={previewAsset} icon={faEye} />
        </OverlayTrigger>
        {downloadZipView()}
      </div>
    )
  }

  const getAssetOption = () => {
    if (heading) {
      return <div></div>;
    }

    if (url === "audio") {
      return getForMedia();
    }
    return getForZip(url);
  }

  const optionView = getAssetOption();

  return (
    <div className={`${style.main} ${textStyle}`}>
      <div className={`${style.main_line} `}><span>{children}</span></div>
      {optionView}
    </div>
  )
}

export default GeneratedAssetView;