import style from './MainHeading.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../utils/Utils';
import StyledButton from '../StyledButton/StyledButton';


const MainHeading = ({children: heading, backButtonAction, editButtonAction }) => {

  const getEditButtonView = () => {
    if (Utils.isNull(editButtonAction)) {
      return null;
    }

    return (
      <StyledButton clickEvent={editButtonAction}>EDIT</StyledButton>
    );
  }

  const editButtonView = getEditButtonView();

  return (
    <div className={style.heading}>
      <FontAwesomeIcon className={style.back_button} icon={faCircleChevronLeft} onClick={backButtonAction}/>
      <span>{heading}</span>
      {editButtonView}
    </div>
  )
}

export default MainHeading;