import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchButton from "../../../../../shared_components/SearchButton/SearchButton";
import style from "./AuditLogsHeader.module.css";
import DateTimeRange from "./DateTimeRange/DateTimeRange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import LoadingPulse from "../../../../../shared_components/LoadingPulse/LoadingPulse";

const AuditLogsHeader = ({
    searchAction, 
    updatePersonSelected,
    listOfStaffMembers,
    dateTimeBetween,
    setDateTimeBetween,
    setFilterByDateValue,
    downloadUserLogs,
    downloadLogs
}) => {

    const updatePersonSelectOptionChanged = (event) => {
        const person = event.target.value === listOfStaffMembers[0] ? "" :  event.target.value;
        updatePersonSelected(person);
    }

    const showDownloadingIcon = () => {
        if (downloadLogs) {
            return <LoadingPulse />
        }
        return (
            <OverlayTrigger placement="left" overlay={<Tooltip id="download-logs">Download Logs</Tooltip>}>
                <FontAwesomeIcon onClick={downloadUserLogs} icon={faDownload} />
            </OverlayTrigger>
        );
    }

    return (
        <div className={style.main}>
            <select onChange={updatePersonSelectOptionChanged}>
                {listOfStaffMembers.map(user => <option key={user}>{user}</option>)}
            </select>
            <DateTimeRange
                dateTimeBetween={dateTimeBetween}
                setDateTimeBetween={setDateTimeBetween}
                setFilterByDateValue={setFilterByDateValue}
            />
            {showDownloadingIcon()}
            <SearchButton searchAction={searchAction} />
        </div>
    )
}

export default AuditLogsHeader;