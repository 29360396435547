import UploadingDetailsStyle from './UploadingDetails.module.css';
import Detail from '../Detail/Detail';

const UploadingDetails = ({ filesUploaded, actionItemsRequired }) => {
  const getFileUploadMessage = () => {
    if (filesUploaded.length === 0) {
      return "NO FILES UPLOADED";
    }
    return filesUploaded.length > 1 ? "FILES UPLOADED" : "FILE UPLOADED";
  }

  const getServerMessages = () => {
    if (actionItemsRequired.length === 0) {
      return "NO MESSAGE FROM SERVER";
    }
    return actionItemsRequired.length > 1 ? "MESSAGES FROM SERVER" : "MESSAGE FROM SERVER";
  }

  const filesUploadedMessage = getFileUploadMessage();
  const userMessages =  getServerMessages();

  const userFilesUploaded = filesUploaded.map((file, index) => <Detail key={file} value={file} heading={false} />);
  const actionMessages = actionItemsRequired.map((message, index) => <Detail key={message + index} value={message} heading={false} />)

  return (
    <div className={UploadingDetailsStyle.plugin_details}>
      <div className={UploadingDetailsStyle.file_list_container}>
        <Detail value={filesUploadedMessage} heading={true}/>
        <div className={UploadingDetailsStyle.file_container}>{userFilesUploaded}</div>
      </div>
      <div className={UploadingDetailsStyle.file_list_container}>
        <Detail value={userMessages} heading={true}/>
        <div className={UploadingDetailsStyle.file_container}>{actionMessages}</div>
      </div>
    </div>
  )
}

export default UploadingDetails;
