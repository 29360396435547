const allowedRoles = [
	'admin',
	'ai_image_admin',
	'ai_image_view',
	'ai_image_create',
];

const deleteRoles = ['admin', 'ai_image_admin'];

const AIImageService = {
	allowedRoles,
	deleteRoles,
};

export default AIImageService;
