import SpreadsheetGuide from "./SpreadSheetGuide/SpreadsheetGuide"
import style from "./DocumentationMenu.module.css";
import SearchMenu from "./SearchMenu/SearchMenu";
import { useState } from "react";
import SearchResult from "./SearchResult/SearchResult";
import Heading from "../../../shared_components/Heading/Heading";

const DocumentationMenu = ({ links }) => {
  const [searchText, setSearchText] = useState("");

  const getSearchView = () => {
    return searchText.length === 0 ? 
      <SpreadsheetGuide links={links} /> : 
      <SearchResult links={links} searchText={searchText}/>;
  }

  const searchView = getSearchView();

  return (
    <div className={style.documentation_menu}>  
      <Heading>Plugin Guides</Heading>
      <SearchMenu searchAction={setSearchText} />
      <div className={style.documentation_view}>
        {searchView}
      </div>
    </div>
  )
}

export default DocumentationMenu;