import { Toast, ToastContainer } from "react-bootstrap";

const CustomToast = ({message, showToast, setShow}) => {
    return (
        <ToastContainer>
            <Toast
                onClose={() => setShow(false)}
                show={showToast}
                delay={3000}
                autohide
                bg={"primary"}
            >
                <Toast.Body className={'text-white'}>
                    {message}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default CustomToast;