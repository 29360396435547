import { OverlayTrigger, Tooltip } from "react-bootstrap";
import style from "./EssayTextArea.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const EssayTextArea = ({question, helpMessage, formData, formKey}) => {
    const [essay, setEssay] = useState(formData[formKey]);

    const updateFormData = (event) => {
        const value = event.target.value;
        formData[formKey] = value;
        setEssay(value);
    }

    return (
        <div key={question} className={style.main}>
            <div className={style.question}>{question}</div>
            <div className={style.text_input}><textarea onChange={updateFormData} value={essay}/></div>
            <div className={style.tooltip}>
                <OverlayTrigger placement="left" overlay={<Tooltip id={question}>{helpMessage}</Tooltip>}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default EssayTextArea;