import style from "./AIRubricMarkLine.module.css";

const AIRubricMarkLine = ({rubric}) => {
    return (
        <div className={style.main}>
            <div className={style.header}>{rubric.rubric}</div>
            <div>{Math.round(rubric.mark)} / 5</div>
        </div>
    )
}

export default AIRubricMarkLine;