import { OverlayTrigger, Tooltip } from "react-bootstrap";
import style from "./WordRange.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const WordRange = ({question, helpMessage, formKey, formData}) => {
    const [fromNumber, setFromNumber] = useState(formData[`${formKey}_from`]);
    const [toNumber, setToNumber] = useState(formData[`${formKey}_to`]);

    const updateFromFormData = (e) => {
        const value = +e.target.value;
        if (value > toNumber) {
            return;
        }
        formData[`${formKey}_from`] = value;
        setFromNumber(value);
    }

    const updateToFormData = (e) => {
        const value = +e.target.value;
        if (value < fromNumber) {
            return;
        }
        formData[`${formKey}_to`] = value;
        setToNumber(value);
    }

    return (
        <div key={question} className={style.main}>
            <div className={style.question}>{question}</div>
            <div className={style.word_range}>
                <div>
                    <span>From</span>
                    <input type="number" onChange={updateFromFormData} value={fromNumber} />
                </div>
                <div>
                    <span>To</span>
                    <input type="number" onChange={updateToFormData} value={toNumber} />
                </div>
            </div>
            <div className={style.tooltip}>
                <OverlayTrigger placement="left" overlay={<Tooltip id={question}>{helpMessage}</Tooltip>}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default WordRange;