import style from "./AIRubricTotalMark.module.css";

const AIRubricTotalMark = ({essay}) => {

    const totalMarkValue = essay.rubricMarks.length * 5;

    return (
        <div className={style.main}>{essay.totalMark} / {totalMarkValue}</div>
    )
}

export default AIRubricTotalMark;