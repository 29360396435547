import style from './AssetPreview.module.css';
import MainHeading from "../../shared_components/MainHeading/MainHeading";
import WhiteMainBackground from "../../shared_components/WhiteMainBackground/WhiteMainBackground";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackgroundButtons from './BackgroundButtons/BackgroundButtons';
import { useCallback, useContext, useEffect, useState } from 'react';
import Utils from '../../utils/Utils';
import Spinner from '../../shared_components/Spinner/Spinner';
import PluginAssetService from '../../services/PluginAssetService';
import UserData from '../../services/UserData';
import InfoPage from '../InfoPage/InfoPage';
import ENV from '../../services/Environment';
import AssetView from '../../shared_components/AssetView/AssetView';

const AssetPreview = () => {
  const location = useLocation();
  const userData = useContext(UserData);
  const { assetName, assetKey, assetType } = useParams();

  const referrer = Utils.getFromLocationOrDefault('referrer', location, `/plugins/asset/interactive/${assetKey}`);
  const urlFromLocation = Utils.getFromLocationOrDefault('url', location, null);

  const navigate = useNavigate();
  const [url, updateUrl] = useState(urlFromLocation);
  const [hasError, updateHasError] = useState(false);
  const [errorMessage, updateErrorMessage] = useState({});
  const [backgroundStyle, updateBackgroundStyle] = useState(ENV.appIconName);

  const backButtonAction = () => {
    navigate(referrer);
  }

  const changeBackground = (selectedStyle) => {
    updateBackgroundStyle(selectedStyle);
  }

  const getIframeView = () => {
    return Utils.isNull(url) ?
      <Spinner /> : 
      <AssetView styleName={backgroundStyle} url={url.location} title={assetName} />;
  }

  const deletePreview = useCallback(() => {
    PluginAssetService.deletePreview(userData, assetName).then(data => {
    }).catch(error => {
      console.error(error);
    })
  }, [userData, assetName]);

  useEffect(() => {
    return deletePreview;
  }, [deletePreview]);


  useEffect(() => {
    if (Utils.isNull(url)) {
      PluginAssetService.previewAsset(userData, assetKey, assetName, assetType).then(data => {
        updateUrl({ location: data.location });
      }).catch(error => {
        updateHasError(true);        
        updateErrorMessage({ reason: "Asset not found", description: Utils.getUsefulError(error)});
      })
    }
  }, [url, assetKey, userData, assetName, assetType]);

  const iframeView = getIframeView();

  const getPreview = () => {
    if (hasError) {
      return <InfoPage reason={errorMessage.reason} description={errorMessage.description} />
    }

    return (
      <>
        {iframeView}
        <BackgroundButtons changeBackground={changeBackground}/>
      </>
    )
  }

  const assetView = getPreview();

  return (
    <WhiteMainBackground>
      <div className={style.main}>
        <MainHeading backButtonAction={backButtonAction}>{assetName}</MainHeading>
        {assetView}
      </div>
    </WhiteMainBackground>
  )
}

export default AssetPreview;