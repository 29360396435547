import { useContext, useEffect, useState } from "react";
import WhiteMainBackgroundWithBackButton from "../../../shared_components/WhiteMainBackgroundWithBackButton/WhiteMainBackgroundWithBackButton"
import EssayTextEdit from "./EssayTextEdit/EssayTextEdit";
import style from "./NewEssayView.module.css";
import AIEssayService from "../../../services/AIEssayService";
import UserData from "../../../services/UserData";
import EssayRubrics from "./EssayRubrics/EssayRubrics";
import EssayTextArea from "./EssayTextArea/EssayTextArea";
import StyledButton from "../../../shared_components/StyledButton/StyledButton";
import WordRange from "./WordRange/WordRange";
import EssaySelectEdit from "./EssaySelectEdit/EssaySelectEdit";
import CustomToast from "../../../shared_components/CustomToast/CustomToast";
import { useNavigate } from "react-router-dom";

const NewEssayView = ({setView}) => {
    const navite = useNavigate();
    const userData = useContext(UserData);
    const [rubrics, setRubrics] = useState([]);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [formData] = useState({
        word_range_from: 250,
        word_range_to: 300,
        topic: "",
        concepts: "",
        learner_level: "School lower level grade",
        rubrics: [],
        content: ""
    });
    const [learnerLevels] = useState(
        [
            "School lower level grade",
            "School higher level grade",
        ]
    )

    const goBackToList = () => {
        setView("list");
    }

    useEffect(() => {
        AIEssayService.fetchRubrics(userData).then(rubricsData => {
            setRubrics(rubricsData.map(item => { 
                return { 
                    label: item,
                    value: item
                }
            }));
        }).catch(error => {
            console.error(error);
        })
    }, [userData])

    const showMessage = (message) => {
        setTimeout(() => {
            setToastMessage(message);
            setShowToast(true);
        }, 100);
    }

    const isNotEmpty = (key, name) => {
        if (formData[key] === "") {
            showMessage(`${name} cannot be empty`);
            return false;
        }
        return true;
    }

    const allPopulated = () => {
        return isNotEmpty("topic", "Topic") && isNotEmpty("concepts", "Concepts") &&
            isNotEmpty("learner_level", "Learner Level") && isNotEmpty("content", "Content");
    }

    const formIsValid = () => {
        if (!allPopulated()) {
            return false;
        }
        
        if (formData.rubrics.length === 0) {
            showMessage("Please select at least one rubric");
            return false;
        }

        return true;
    }

    const submitForMarking = () => {
        if (!formIsValid()) {
            return;
        }
        const aiEssayMarkingRequest = {
            learnerGuid: "",
            prompt: "",
            essay: formData.content,
            topic: formData.topic,
            keyConcepts: formData.concepts,
            learnerLevel: formData.learner_level,
            wordRange: `${formData.word_range_from} and ${formData.word_range_to}`,
            rubrics: formData.rubrics
        }
        AIEssayService.sendForMarking(userData, aiEssayMarkingRequest).then(data => {
            showMessage("Sending essay for marking");
            setTimeout(() => {
                navite(`/ai-essay/mark/${data.guid}`);
            }, 150);
        }).catch(error => {
            showMessage("Error sending essay for marking");
        })
    }

    return (
        <WhiteMainBackgroundWithBackButton
            heading={"AI Marking Test"}
            backButtonAction={() => goBackToList()}
        >
            <CustomToast message={toastMessage} showToast={showToast} setShow={setShowToast}/>            
            <div className={style.main}>
                <EssayTextEdit question="Topic" helpMessage="Topic the essay is based on." formData={formData} formKey="topic" />
                <EssayTextEdit question="Key Concepts" helpMessage="Key concepts the marking should be based on. Concepts should be comma seperated." formData={formData} formKey="concepts" />
                <EssaySelectEdit question="Learner Level" helpMessage="What grade is the learner, this is used to mark the essay fairly" formData={formData} formKey="learner_level" learnerGrades={learnerLevels}/>
                <WordRange question="Word Range" helpMessage="What is the minimum and maximum words" formData={formData} formKey="word_range" />
                <EssayRubrics question="Rubrics" helpMessage="Select the rubrics to use when grading the piece" rubrics={rubrics} formData={formData} formKey="rubrics" />
                <EssayTextArea question="Content" helpMessage="Type your content" formData={formData} formKey="content" />
                <StyledButton clickEvent={submitForMarking}>SUBMIT</StyledButton>
            </div>
        </WhiteMainBackgroundWithBackButton>
    )
}

export default NewEssayView;