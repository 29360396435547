import Utils from "../../../../utils/Utils";
import style from './SearchResult.module.css';

const SearchResult = ({links, searchText}) => {

  const getSearchResults = () => {
    if (Utils.isNull(links) || searchText.length === 0) {
      return <div>Nothing to seach</div>
    }

    const searchResults = links
      .filter(link => !Utils.isNull(link))
      .filter(link => link.name.toUpperCase().indexOf(searchText.toUpperCase()) !== -1)
      .map((link, index) => <div key={`search_div_${link.name.replace(" ", "_")}_${index}`} className={style.search_result}><a key={`search_a_${link.name.replace(" ", "_")}_${index}`} href={link.link}>{link.name}</a></div>);
    
    if (searchResults.length === 0) {
      return <div>No search results</div>
    }

    const resultString = searchResults.length === 1 ? "Search result" : "Search results";

    return (
      <div>
        <div>{resultString}</div>
        {searchResults}
      </div>
    )
  }

  const searchResults = getSearchResults();

  return (
    searchResults
  )
}

export default SearchResult;