import style from "./EditUserRoles.module.css";
import EditRoleGroup from "./EditRoleGroup/EditRoleGroup";
import MainContainerWithBackButtonAndSaveButton from "../../../../../shared_components/MainContainerWithBackButtonAndActionIcon/MainContainerWithBackButtonAndActionIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import RolesService from "../../../../../services/RolesService";
import UserData from "../../../../../services/UserData";
import LoadingPulse from "../../../../../shared_components/LoadingPulse/LoadingPulse";
import CustomToast from "../../../../../shared_components/CustomToast/CustomToast";

const EditUserRoles = ({user, roles, backButtonAction}) => {
    const [ updating, setUpdating ] = useState(false);
    const [ showToast, setShowToast ] = useState(false);
    const [ toastMessage, setToastMessage ] = useState("");

    const userData = useContext(UserData);

    const saveUserGroup = () => {
        setUpdating(true);
        RolesService.saveRolesForUser(userData, user).then(data => {            
            setUpdating(false);
            setShowToast(true);
            setToastMessage("SAVED");
        }).catch(error => {
            console.error(error);
            setUpdating(false);
            setToastMessage("ERROR");
        });
    }

    const icon = (updating) ? <LoadingPulse /> : <FontAwesomeIcon icon={faFloppyDisk} onClick={saveUserGroup}/>
    
    return (        
        <MainContainerWithBackButtonAndSaveButton heading={user.username} backButtonAction={backButtonAction} icon={icon}>
            <div className={style.roles}>
                {roles.map(role => <EditRoleGroup key={`${role.group}`} role={role} user={user} />)}
            </div>
            <CustomToast message={toastMessage} showToast={showToast} setShow={setShowToast}/>
        </MainContainerWithBackButtonAndSaveButton>
    )
}

export default EditUserRoles;