import { useState } from 'react';
import style from './EssayRubrics.module.css';
import { MultiSelect } from 'react-multi-select-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const EssayRubrics = ({
	question,
	rubrics,
	helpMessage,
	formKey,
	formData,
}) => {
	const [rubricsSelected, setRubricsSelected] = useState([]);

	const updateFormData = (selectedRubrics) => {
		formData[formKey] = selectedRubrics
			.map((rubric) => rubric.value)
			.join(', ');
		setRubricsSelected(selectedRubrics);
	};

	return (
		<div key={question} className={style.main}>
			<div className={question}>{question}</div>
			<div className={style.select_input}>
				<MultiSelect
					options={rubrics}
					value={rubricsSelected}
					onChange={updateFormData}
					labelledBy='Select'
				/>
			</div>
			<div className={style.tooltip}>
				<OverlayTrigger
					placement='left'
					overlay={<Tooltip id={question}>{helpMessage}</Tooltip>}>
					<FontAwesomeIcon icon={faQuestionCircle} />
				</OverlayTrigger>
			</div>
		</div>
	);
};

export default EssayRubrics;
