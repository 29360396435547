import { useState } from "react";
import AIEssaysList from "./AIEssaysList/AIEssaysList";
import NewEssayView from "./NewEssayView/NewEssayView";

const AIEssay = () => {
    const [visibleView, setVisibleView] = useState("list");

    const mainView = visibleView === "list" ? <AIEssaysList setView={setVisibleView}/> : <NewEssayView setView={setVisibleView}/>;

    return (
        mainView
    );
}

export default AIEssay;