import React, { useCallback, useState } from 'react';
import style from './ImagePreviewPanel.module.css';
import StyledButton from '../../../shared_components/StyledButton/StyledButton';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from '../CountDownTimer/CountDownTimer';
import ImageModal from '../ImageModal/ImageModal';
import ErrorBanner from '../ErrorBanner/ErrorBanner';
import ImageService from '../../../services/ImageService';

const ImagePreviewPanel = ({
	handleRegenerateSubmit,
	setImageData,
	imageData,
	generateImage,
	handleInputChange,
	imageRegularData,
	setImageRegularData,
	textAreaIsDisabled,
	isLoading,
	setIsLoading,
	errorMessage,
	setErrorMessage,
	setIsGenerateFormSubmitted,
	userData,
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const navigate = useNavigate();

	const handleChange = useCallback(
		(event) => {
			setImageRegularData(event.target.value);
			handleInputChange(event);
		},
		[setImageRegularData, handleInputChange]
	);

	const handleImageClick = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const handleDownload = () => {
		if (imageData) {
			ImageService.handleDownload(imageData);
		}
	};

	const handleCopyPrompt = () => {
		if (imageData) {
			ImageService.handleCopyPrompt(imageData);
		}
	};

	const handleViewImage = () => {
		if (imageData) {
			navigate(`/gallery/image/${imageData.imageID}`);
		}
	};

	const handleDeleteImage = (imageId) => {
		if (!imageId) {
			return;
		}
		ImageService.deleteImage(imageId, userData, userData.jwtToken)
			.then(() => {
				setImageData(null);
				setModalOpen(false);
			})
			.catch((error) => {
				setErrorMessage('Failed to delete the image. Please try again.');
			});
	};

	return (
		<div className={style.middleContainer}>
			<div className={`${style.middleblock} ${style.size}`}>
				{errorMessage && (
					<ErrorBanner
						message={errorMessage}
						onDismiss={() => setErrorMessage(null)}
					/>
				)}
				<div className={style.ImageContainer}>
					{isLoading ? (
						<CountdownTimer initialSeconds={90} />
					) : imageData && imageData.base64_medium ? (
						<img
							src={`data:image/png;base64,${imageData.base64_medium}`}
							alt={imageData.promptUsed || ''}
							onLoad={() => setIsLoading(false)}
							onClick={handleImageClick}
							className={style.image}
						/>
					) : (
						<div className={style.placeholder}></div>
					)}
				</div>
				<div className={style.formPosition}>
					<h1>Revised Prompt</h1>
					<form
						className='formContainer'
						onSubmit={(event) =>
							handleRegenerateSubmit(
								event,
								generateImage,
								imageRegularData,
								setImageData,
								setIsGenerateFormSubmitted,
								setErrorMessage
							)
						}>
						<textarea
							disabled={textAreaIsDisabled}
							value={imageRegularData}
							onChange={handleChange}
							placeholder='Type something...'
							rows={6}
							cols={50}
							className={style.textareaField}
							aria-label='Prompt input field'
						/>
						<StyledButton
							type='submit'
							disabled={textAreaIsDisabled || isLoading}
							className={`${style.styledButton} ${
								textAreaIsDisabled || isLoading ? style.disabledButton : ''
							}`}>
							{'Regenerate image'}
						</StyledButton>
					</form>
				</div>
			</div>
			<ImageModal
				isOpen={modalOpen}
				onClose={closeModal}
				prompt={imageRegularData}
				onDownload={handleDownload}
				onCopy={handleCopyPrompt}
				onEdit={handleViewImage}
				userData={userData}
				onDelete={handleDeleteImage}
				imageId={imageData ? imageData.imageID : null}>
				{imageData && (
					<img
						src={`data:image/png;base64,${imageData.base64_medium}`}
						alt={imageData.promptUsed || ''}
					/>
				)}
			</ImageModal>
		</div>
	);
};

export default ImagePreviewPanel;
