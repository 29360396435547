import ThemedBtn from './ThemedBtn/ThemedBtn';
import ButtonViewStyle from './ButtonView.module.css';

const ButtonView = ( { buttonsActiveStatus, buttonActions, generateType }) => {
  return (
    <div className={ButtonViewStyle.button_div}>
      <ThemedBtn 
        isDisabled={buttonsActiveStatus.generateDisabled}
        buttonAction={buttonActions.generatePlugin}
      >GENERATE {generateType}</ThemedBtn>
    </div>
  )
}

export default ButtonView;