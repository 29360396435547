import { useContext, useEffect, useState } from 'react';
import SubMenuWhiteBackground from '../../shared_components/SubMenuWhiteBackground/SubMenuWhiteBackground';
import WhiteMainBackground from '../../shared_components/WhiteMainBackground/WhiteMainBackground';
import style from './PluginGlossary.module.css';
import PluginGlossaryMenu from './PluginGlossaryMenu/PluginGlossaryMenu';
import PluginGlossaryView from './PluginGlossaryView/PluginGlossaryView';
import PluginGlossaryService from '../../services/PluginGlossaryService';
import UserData from '../../services/UserData';

const PluginGlossary = () => {
	const userData = useContext(UserData);
	const [plugins, setPluginList] = useState([]);
	const [activePlugin, setActivePlugin] = useState(null);
	const [error, setErrorMessage] = useState(null);
	const [activeIndex, setActiveIndex] = useState(-1);

	const updateActivePlugin = (index) => {
		setActivePlugin(plugins[index]);
		setActiveIndex(index);
	};

	useEffect(() => {
		PluginGlossaryService.getPluginGlossaryList(userData)
			.then((data) => {
				setPluginList(data);
			})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	}, [setPluginList, userData]);

	return (
		<div className={style.plugin_glossary}>
			<SubMenuWhiteBackground>
				<PluginGlossaryMenu
					plugins={plugins}
					updateActivePlugin={updateActivePlugin}
					activeIndex={activeIndex}
				/>
			</SubMenuWhiteBackground>
			<WhiteMainBackground>
				<PluginGlossaryView activePlugin={activePlugin} error={error} />
			</WhiteMainBackground>
		</div>
	);
};

export default PluginGlossary;
