import UploadingDetails from './UploadingDetails/UploadingDetails';
import GeneratingDetails from './GeneratingDetails/GeneratingDetails';

const AssetDetails = ({ filesUploaded, actionItemsRequired, generating, generatingLogs }) => {
  const detailsView = generating ? 
    <GeneratingDetails generatingLogs={generatingLogs} />:
    <UploadingDetails filesUploaded={filesUploaded} actionItemsRequired={actionItemsRequired}/>;

  return (
    detailsView
  )
}

export default AssetDetails;