import Utils from '../../utils/Utils';
import style from './InfoPage.module.css';

const InfoPage = ({title, reason, description}) => {
  const pageTitle = Utils.isNull(title) ? "ERROR" : title;
  return (
      <div className={style.error}>
        <div className={style.notfounddiv}>
          <div className={style.error_heading}>{pageTitle}</div>
          <div className={style.error_reason}>{reason}</div>
          <div className={style.error_description}>{description}</div>
        </div>
      </div>
  );
}

export default InfoPage;