import Utils from "../utils/Utils";
import TokenService from "./TokenService";
import UserService from "./UserService";

const logoutUser = (userData) => {
  UserService.logOutUser(userData);
  window.location.reload();
  return null;
}

const getRequest = async(url, jwtToken, userData, refreshToken) => {
  const mustRefresh = refreshToken === undefined ? true : refreshToken;
  const result = await makeGetRequest(url, jwtToken);
  if (result.status === 403 && mustRefresh) {
    const refreshed = await TokenService.refreshToken(userData);
    if (Utils.isNull(refreshed) || !refreshed) {
      return logoutUser(userData);
    }
    return await makeGetRequest(url, userData.jwtToken);
  }
  return result;
}

const postRequest = async(url, jwtToken, body, userData, refreshToken) => {
  const mustRefresh = refreshToken === undefined ? true : refreshToken;
  const result = await makePostRequest(url, jwtToken, body);
  if (result.status === 403 && mustRefresh) {
    const refreshed = await TokenService.refreshToken(userData);
    if (Utils.isNull(refreshed) || !refreshed) {
      return logoutUser(userData);
    }
    return await makePostRequest(url, userData.jwtToken, body);
  }
  return result;
}

const putRequest = async(url, jwtToken, body, userData, refreshToken) => {
  const mustRefresh = refreshToken === undefined ? true : refreshToken;
  const result = await makePutRequest(url, jwtToken, body);
  if (result.status === 403 && mustRefresh) {
    const refreshed = await TokenService.refreshToken(userData);
    if (Utils.isNull(refreshed) || !refreshed) {
      return logoutUser(userData);
    }
    return await makePutRequest(url, userData.jwtToken, body);
  }
  return result;
}

const deleteRequest = async(url, userData, refreshToken) => {
  const mustRefresh = refreshToken === undefined ? true : refreshToken;
  const result = await makeDeleteRequest(url, userData.jwtToken);
  if (result.status === 403 && mustRefresh) {
    const refreshed = await TokenService.refreshToken(userData);
    if (Utils.isNull(refreshed) || !refreshed) {
      return logoutUser(userData);
    }
    return await makeDeleteRequest(url, userData.jwtToken);
  }
  return result;
}

const makeGetRequest = async(url, jwtToken) => {
  return await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })
}

const makePostRequest = async(url, jwtToken, body) => {
  return await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
}

const makePutRequest = async(url, jwtToken, body) => {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
}

const makeDeleteRequest = async(url, jwtToken) => {
  return await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    }
  })
}

const Requests = {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest
}

export default Requests;