import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageGrid from './ImageGrid/ImageGrid';
import styles from './AiImageGeneratorHome.module.css';
import StyledButton from '../../shared_components/StyledButton/StyledButton';
import ImageService from '../../services/ImageService';
import UserData from '../../services/UserData';

function AiImageGeneratorHome() {
	const navigate = useNavigate();
	const [images, setImages] = useState([]);
	const [filteredImages, setFilteredImages] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const userData = useContext(UserData);
	const fetchTotalPages = useCallback(() => {
		if (!userData) return;
		ImageService.getTotalPages(userData.jwtToken, userData)
			.then((totalPages) => {
				setTotalPages(totalPages);
			})
			.catch((error) => {});
	}, [userData]);

	const fetchImages = useCallback(
		(page = 1) => {
			if (!userData) return;
			setLoading(true);
			ImageService.getAllImages(userData.jwtToken, userData, page - 1, 6)
				.then((response) => {
					setImages(response.content);
					setFilteredImages(response.content);
					setCurrentPage(page);
				})
				.catch((error) => {})
				.finally(() => {
					setLoading(false);
				});
		},
		[userData]
	);

	useEffect(() => {
		fetchImages(1);
		fetchTotalPages();
	}, [fetchTotalPages, fetchImages]);

	const handleGenerateImageClick = () => {
		navigate('/image/generate', { state: { filteredImages } });
	};

	const handleSearchSubmit = (searchTerm) => {
		setLoading(true);
		ImageService.searchImages(userData.jwtToken, userData, searchTerm)
			.then((searchResults) => {
				setFilteredImages(searchResults.content);
				setTotalPages(Math.ceil(searchResults.content.length / 6));
				setSearchQuery(searchTerm);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const handlePageChange = (newPage) => {
		fetchImages(newPage);
	};

	return (
		<div>
			<div className={styles.centeredButtonContainer}>
				<StyledButton
					className={styles.centeredButton}
					onClick={handleGenerateImageClick}>
					Generate Image
				</StyledButton>
			</div>
			<ImageGrid
				images={images}
				searchQuery={searchQuery}
				handleSearchSubmit={handleSearchSubmit}
				filteredImages={filteredImages}
				setFilteredImages={setFilteredImages}
				loading={loading}
				currentPage={currentPage}
				totalPages={totalPages}
				onPageChange={handlePageChange}
				getImageUrl={(imageId) => `/gallery/image/${imageId}`}
				jwtToken={userData.jwtToken}
				userData={userData}
				setCurrentPage={setCurrentPage}
			/>
		</div>
	);
}

export default AiImageGeneratorHome;
