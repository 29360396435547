import AuditLogsListBody from "./AuditLogsListBody/AuditLogsListBody";
import AuditLogsListHeadings from "./AuditLogsListHeading/AuditLogsListHeading";
import style from "./AuditLogsBody.module.css";

const AuditLogsBody = ({auditLogs, setSortDirectionSelection, sortDirection}) => {

    return (
        <div className={style.main}>
            <AuditLogsListHeadings
                setSortDirectionSelection={setSortDirectionSelection}
                sortDirection={sortDirection}
            />
            <AuditLogsListBody auditLogs={auditLogs} />            
        </div>
    )
}

export default AuditLogsBody;