import { Button } from "react-bootstrap";
import style from "./UserItem.module.css";
import AuthService from "../../../../../services/AuthService";
import { useContext, useState } from "react";
import UserData from "../../../../../services/UserData";
import LoadingPulse from "../../../../../shared_components/LoadingPulse/LoadingPulse";
import Permissions from "../../../../../services/Permissions";

const UserItem = ({userItem, heading, updateList}) => {
    const userData = useContext(UserData);
    const [updating, setUpdating] = useState(false);

    const disableUser = () => {
        setUpdating(true);
        AuthService.disableUser(userData, userItem.username).then(data => {
            setUpdating(false);
            updateList();
        }).catch(error => {
            console.error(error.message);
            setUpdating(false);
        })
    }

    const enableUser = () => {
        setUpdating(true);
        AuthService.enableUser(userData, userItem.username).then(() => {
            setUpdating(false);
            updateList();
        }).catch(error => {
            console.error(error.message);
            setUpdating(false);
        })
    }

    const enableOrDisableUser = (enable) => {
        if (enable) {
            enableUser();
        } else {
            disableUser();
        }
    }

    const getActionView = () => {
        if (heading) {
            return <span>Action</span>;
        }

        if (updating) {
            return <LoadingPulse />
        }

        if (Permissions.isAdmin(userItem)) {
            return null;
        }

        return userItem.active ? 
            <Button variant="danger" onClick={() => enableOrDisableUser(false)}>Deactivate</Button> :
            <Button variant="success" onClick={() => enableOrDisableUser(true)}>Activate</Button>;
    }

    const getLineStyle = () => {
        return heading ? style.heading : null;
    }

    return (
        <div className={`${style.user_container} ${getLineStyle()}`}>
            <span>{userItem.username}</span>
            <span>{userItem.role}</span>
            <span>{userItem.active ? "Active" : "Disabled"}</span>
            {getActionView()}
        </div>
    );
}

export default UserItem;