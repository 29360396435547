import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ENV from "../../../services/Environment";
import AssetView from "../../../shared_components/AssetView/AssetView";
import Heading from "../../../shared_components/Heading/Heading";
import Utils from "../../../utils/Utils";
import InfoPage from "../../InfoPage/InfoPage";
import style from "./PluginGlossaryView.module.css";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import DownloadService from "../../../services/DownloadService";
import UserData from "../../../services/UserData";
import { useContext, useState } from "react";
import LoadingPulse from "../../../shared_components/LoadingPulse/LoadingPulse";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PluginGlossaryView = ({activePlugin, error}) => {
  const userData = useContext(UserData);
  const [downloading, setDownloading] = useState(false);

  const getInstruction = () => {
    return (
      <InfoPage 
        title="INFO"
        reason="Select plugin to view"
        description="Please select a plugin on the left to view"/>
    );
  }

  const downloadFile = () => {
    const glossaryName = activePlugin.name.replaceAll(" ", "_");
    const downloadFilename = `${activePlugin.name}.xlsx`;
    DownloadService.downloadGlossarySpreadsheet(glossaryName, userData.jwtToken, downloadFilename).then(data => {
      setDownloading(false);
    }).catch(error => {
      setDownloading(false);
    })
    setDownloading(true);
  }

  const getDownloadIconWithToolTip = () => {
    return (
      <OverlayTrigger placement="left" overlay={<Tooltip id={activePlugin.name}>Download Spreadsheet</Tooltip>}>
        <FontAwesomeIcon onClick={downloadFile} icon={faDownload}/>
      </OverlayTrigger>
    )
  }

  const getDownloadView = () => {
    return downloading ? <LoadingPulse /> : getDownloadIconWithToolTip();
  }

  const getActivePluginDiv = () => {
    const url = `${ENV.base_url}${activePlugin.previewLink}`;
    return (
      <div className={style.glossary_view}>
        <Heading>{activePlugin.name}</Heading>
        <AssetView 
          url={url}
          styleName={ENV.appIconName}
          title={activePlugin.name}
        />
        <div className={style.download_div}>{getDownloadView()}</div>
      </div>
    );
  }

  const getActivePlugin = () => {
    if (!Utils.isNull(error)) {
      return <InfoPage description={error} reason="Unable to preview plugin asset" />
    }

    if (Utils.isNull(activePlugin)) {
      return getInstruction();      
    }

    return getActivePluginDiv();
  }

  const pluginView = getActivePlugin();

  return (
    pluginView
  )
}

export default PluginGlossaryView;