import AuditInformationLine from "../AuditInformationLine/AuditInformationLine";
import style from "./AuditLogsListBody.module.css";

const AuditLogsListBody = ({auditLogs}) => {
    return (
        <div className={style.main}>
            {auditLogs.map((auditLog, index) => <AuditInformationLine key={`${auditLog.timeStamp}-${index}`} auditData={auditLog}/>)}
        </div>
        
    );
}

export default AuditLogsListBody;