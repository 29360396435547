import Heading from "../../../shared_components/Heading/Heading"
import MenuItem from "../../../Menu/MenuItem/MenuItem";
import SubMenuContainer from "../../../shared_components/SubMenuContainer/SubMenuContainer";

const UsersMenu = () => {
  return (
    <SubMenuContainer>
      <Heading>Users Menu</Heading>

      <MenuItem path="access-history">Access History</MenuItem>
      <MenuItem path="user-management">User Management</MenuItem>
      <MenuItem path="user-roles">User Roles</MenuItem>
      <MenuItem path="audit-logs">Audit Logs</MenuItem>
    </SubMenuContainer>
  )
}

export default UsersMenu;