import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingPulse from "../../../../shared_components/LoadingPulse/LoadingPulse";
import style from "./MarkingLine.module.css";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../../utils/Utils";
import { useNavigate } from "react-router-dom";

const MarkingLine = ({essay, heading}) => {
    const navigate = useNavigate();

    const getIconStatus = (status) => {
        if (status === "PENDING") {
            return <LoadingPulse />
        }

        if (status === "FAIL") {
            return <FontAwesomeIcon icon={faCircleXmark} className={style.error} />
        }
        return <FontAwesomeIcon icon={faCircleCheck} className={style.success} />
    }

    const isHeading = () => {
        return !Utils.isNull(heading) && heading;
    }

    const getPercentage = () => {
        if (essay.status !== "SUCCESS" || Utils.isNull(essay.rubricMarks) || essay.rubricMarks.length === 0) {
            return <span>0%</span>;
        }
        const totalMarkForEssay = essay.rubricMarks.length * 5;
        const learnerpercentage = Math.round((essay.totalMark / totalMarkForEssay) * 100);
        return <span>{learnerpercentage}%</span>
    }

    const goToMark = () => {
        if (isHeading()) {
            return;
        }
        navigate(`/ai-essay/mark/${essay.guid}`);
    }

    const headingStyle = isHeading() ? style.heading : null

    return (
        <div className={style.main} onClick={goToMark}>
            <div className={isHeading() ? style.heading : style.icon}>{isHeading() ? "STATUS" : getIconStatus(essay.status)}</div>
            <div className={`${style.username} ${headingStyle}`}>{isHeading() ? "USERNAME" : essay.username}</div>
            <div className={`${style.topic} ${headingStyle}`}>{isHeading() ? "TOPIC" : essay.topic}</div>
            <div className={`${style.mark} ${headingStyle}`}>{isHeading() ? "PERCENTAGE" : getPercentage()}</div>
        </div>
    )
}

export default MarkingLine;