import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ImageService from '../../../services/ImageService';
import UserData from '../../../services/UserData';
import WhiteMainBackground from '../../../shared_components/WhiteMainBackground/WhiteMainBackground';
import Spinner from '../../../shared_components/Spinner/Spinner';
import styles from './AiImageView.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

const AiImageView = () => {
	const { imageId } = useParams();
	const navigate = useNavigate();
	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const userData = useContext(UserData);
	const baseUrl = window.location.origin;

	useEffect(() => {
		const fetchImage = () => {
			setLoading(true);
			setError(null);
			ImageService.getImageById(userData.jwtToken, userData, imageId)
				.then((fetchedImage) => {
					setImage(fetchedImage);
				})
				.catch((error) => {
					setError(`Failed to load image: ${error.message}`);
				})
				.finally(() => {
					setLoading(false);
				});
		};
		fetchImage();
	}, [imageId, userData]);

	const handleGoBack = () => {
		navigate(-1);
	};

	const handleDownload = () => {
		if (image) {
			ImageService.handleDownload(image);
		}
	};

	const handleCopyPath = () => {
		const imageUrl = `${baseUrl}/gallery/image/${imageId}`;
		navigator.clipboard
			.writeText(imageUrl)
			.then(() => {
				alert('Image URL copied to clipboard!');
			})
			.catch((error) => {
				console.error('Failed to copy image URL:', error);
			});
	};

	const handleCopyPrompt = () => {
		if (image) {
			ImageService.handleCopyPrompt(image);
		}
	};

	if (loading) {
		return (
			<WhiteMainBackground>
				<Spinner />
			</WhiteMainBackground>
		);
	}

	if (error) {
		return (
			<WhiteMainBackground>
				<p>{error}</p>
			</WhiteMainBackground>
		);
	}

	if (!image) {
		return (
			<WhiteMainBackground>
				<p>Image not found</p>
			</WhiteMainBackground>
		);
	}

	return (
		<WhiteMainBackground>
			<div className={styles.heading}>
				<FontAwesomeIcon
					className={`${styles.back_button} ${loading ? styles.disabled : ''}`}
					icon={faCircleChevronLeft}
					onClick={!loading ? handleGoBack : undefined}
					tabIndex={0}
					aria-label='Go back'
				/>
				<span>Generated Image</span>
			</div>
			<div className={styles.imageContainer}>
				{image.base64 || image.base64_medium || image.base64_thumbnail ? (
					<img
						src={`data:image/jpeg;base64,${
							image.base64 || image.base64_medium || image.base64_thumbnail
						}`}
						alt='Generated images'
						className={styles.image}
					/>
				) : (
					<p>Image data is missing</p>
				)}
				<div className={styles.buttonContainer}>
					<button onClick={handleDownload} className={styles.button}>
						Download
					</button>
					<button onClick={handleCopyPath} className={styles.button}>
						Copy URL
					</button>
					<button onClick={handleCopyPrompt} className={styles.button}>
						Copy Prompt
					</button>
				</div>
			</div>
		</WhiteMainBackground>
	);
};

export default AiImageView;
