import style from "./AIMarkingFooter.module.css";
import AIRubricMarkLine from "./AIRubricMarkLine/AIRubricMarkLine";
import AIRubricTotalMark from "./AIRubricTotalMark/AIRubricTotalMark";
import AITeacherResponse from "./AITeacherResponse/AITeacherResponse";

const AIMarkingFooter = ({essay}) => {
    return (
        <div className={style.main}>
            <AITeacherResponse response={essay.aiTeachersComment}/>
            <div className={style.rubrics}>
                {
                    essay.rubricMarks.map(rubric => <AIRubricMarkLine key={rubric.rubric} rubric={rubric} />)
                }
            </div>
            <AIRubricTotalMark essay={essay} />
        </div>
    )
}

export default AIMarkingFooter;