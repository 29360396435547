import React, { useState, useContext, useEffect, useCallback } from 'react';
import WhiteMainBackground from '../../../shared_components/WhiteMainBackground/WhiteMainBackground';
import style from './AiImageGeneratorView.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ImageGeneratorForm from '../ImageGeneratorForm/ImageGeneratorForm';
import ImagePreviewPanel from '../ImagePreviewPanel/ImagePreviewPanel';
import useDebouncedCallback from '../hooks/useDebouncedCallback';
import useImageGeneration from '../hooks/useImageGeneration';
import usePolling from '../hooks/usePolling';
import UserData from '../../../services/UserData';
import ImageService from '../../../services/ImageService';

function AiImageGeneratorView() {
	const [textValue, setTextValue] = useState('');
	const [imageData, setImageData] = useState(null);
	const [imageRegularData, setImageRegularData] = useState('');
	const [isInputEmpty, setIsInputEmpty] = useState(true);
	const [textAreaIsDisabled, setTextAreaIsDisabled] = useState(true);
	const [selectedValue, setSelectedValue] = useState('1792x1024');
	const [isGenerateFormSubmitted, setIsGenerateFormSubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const { generateImage, imageID, isLoading, setIsLoading } =
		useImageGeneration();
	const userData = useContext(UserData);

	usePolling(
		imageID,
		userData,
		setImageData,
		setImageRegularData,
		setTextAreaIsDisabled,
		setIsLoading,
		setErrorMessage
	);

	const handleChange = useCallback((event) => {
		setTextValue(event.target.value);
	}, []);

	const handleInputChange = useDebouncedCallback((event) => {
		setIsInputEmpty(event.target.value.trim().length <= 3);
	}, 300);

	const handleItemClick = useCallback(
		(value) => {
			if (!isGenerateFormSubmitted) {
				setSelectedValue(value);
			}
		},
		[isGenerateFormSubmitted]
	);

	const goBack = () => {
		window.history.back();
	};

	const updateImageRegularData = useCallback(() => {
		if (imageData?.revisedPrompt) {
			setImageRegularData(imageData.revisedPrompt);
		}
	}, [imageData]);

	useEffect(() => {
		updateImageRegularData();
	}, [updateImageRegularData]);

	return (
		<WhiteMainBackground className={style.whiteMainBackground}>
			<div className={style.heading}>
				<FontAwesomeIcon
					className={`${style.back_button} ${isLoading ? style.disabled : ''}`}
					icon={faCircleChevronLeft}
					onClick={!isLoading ? goBack : undefined}
					tabIndex={0}
					aria-label='Go back'
				/>
				<span>New Asset</span>
			</div>

			<div className={style.mainContainer}>
				<ImageGeneratorForm
					handleSubmit={(event, textValue, selectedOption) =>
						ImageService.handleGenerateSubmit(
							event,
							generateImage,
							textValue,
							selectedOption,
							selectedValue,
							setImageData,
							setIsGenerateFormSubmitted,
							setErrorMessage
						)
					}
					textValue={textValue}
					handleChange={handleChange}
					handleInputChange={handleInputChange}
					isInputEmpty={isInputEmpty}
					handleItemClick={handleItemClick}
					isLoading={isLoading}
					textAreaIsDisabled={textAreaIsDisabled}
				/>
				<ImagePreviewPanel
					handleRegenerateSubmit={(event) =>
						ImageService.handleRegenerateSubmit(
							event,
							generateImage,
							imageRegularData,
							setImageData,
							setIsGenerateFormSubmitted,
							setErrorMessage
						)
					}
					setImageData={setImageData}
					imageData={imageData}
					imageRegularData={imageRegularData}
					setImageRegularData={setImageRegularData}
					handleInputChange={handleInputChange}
					textAreaIsDisabled={textAreaIsDisabled}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					imageID={imageID}
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
					userData={userData}
				/>
			</div>
		</WhiteMainBackground>
	);
}

export default AiImageGeneratorView;
