import Utils from "../utils/Utils";
import ENV from "./Environment";
import Requests from "./Requests";

const baseUrl = `${ENV.base_url}/api/v1/audit-logs`;

const getAuditLogs = async(
  userData,
  page,
  searchText,
  personSelected,
  filterByDateValue,
  dateTimeBetween,
  sortDirection
) => {
  const url = new URL(`${baseUrl}/logs`);
  url.searchParams.append("page", page - 1);
  url.searchParams.append("searchText", Utils.sanitizeText(searchText));
  url.searchParams.append("personSelected", personSelected);
  url.searchParams.append("filterByDate", filterByDateValue);
  url.searchParams.append('sortDirection', sortDirection)
  if (!Utils.isNull(dateTimeBetween)) {
    url.searchParams.append("dateFrom", dateTimeBetween.from);
    url.searchParams.append("dateTo", dateTimeBetween.to);
  }
  const result = await Requests.getRequest(url.toString(), userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(Utils.getErrorFromRequest(data));
  }
  return data;
}

const getUsernames = async(
  userData
) => {
  const url = new URL(`${baseUrl}/usernames`);
  const result = await Requests.getRequest(url.toString(), userData.jwtToken, userData);
  const data = await result.clone().json();  
  if (!result.ok) {
    throw new Error(Utils.getErrorFromRequest(data));
  }
  return data;
}

const getDatesInBetween = async(
  userData
) => {
  const url = new URL(`${baseUrl}/dates-between`);
  const result = await Requests.getRequest(url.toString(), userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(Utils.getErrorFromRequest(data));
  }
  return data;
}

const AuditLogsService = {
  getAuditLogs,
  getUsernames,
  getDatesInBetween
}

export default AuditLogsService;
