import style from "./SubMenuContainer.module.css";

const SubMenuContainer = ({children}) => {
  return (
    <div className={style.sub_menu_container}>
      {children}
    </div>
  )
}

export default SubMenuContainer;