import { createContext } from 'react';

const UserData = createContext({
  jwtToken: null,
  username: "",
  checking: true,
  postError: null,
  version: null,
  refreshToken: null,
  roles: [],
  lastActionTimeStamp: null
});

export default UserData;