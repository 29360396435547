import ENV from './Environment';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1/ai_image/generate`;
const baseUrlStatus = `${ENV.base_url}/api/v1/ai_image/status/`;
const baseUrlImage = `${ENV.base_url}/api/v1/ai_image/image/`;
const baseUrlList = `${ENV.base_url}/api/v1/ai_image/images/list`;
const baseUrlDelete = `${ENV.base_url}/api/v1/ai_image/image`;

const getImageStream = async (path, jwtToken, userData) => {
	const result = await Requests.getRequest(
		`${baseUrlStatus}${path}`,
		jwtToken,
		userData
	);
	if (!result.ok) {
		throw new Error('Unable to download image');
	}
	return await result.json();
};

const getBase64Image = async (path, jwtToken, userData) => {
	const result = await Requests.getRequest(
		`${baseUrlImage}${path}/medium`,
		jwtToken,
		userData
	);

	if (!result.ok) {
		const errorText = await result.text();
		console.error('API error response:', errorText);
		throw new Error(`Unable to download image: ${result.status} ${errorText}`);
	}

	const data = await result.json();

	if (typeof data === 'string') {
		return { base64: data };
	}

	if (data.base64 || data.base64_medium || data.base64_thumbnail) {
		return data;
	}

	throw new Error('Unexpected data structure received from API');
};

const generateImage = async (path, jwtToken, userData, options) => {
	const { prompt, imageDimension } = options;

	try {
		const result = await Requests.postRequest(
			baseUrl,
			jwtToken,
			{
				prompt: prompt,
				imageDimension: imageDimension,
			},
			userData
		);

		if (!result.ok) {
			const errorData = await result.json();
			throw new Error('Unable to generate image', errorData);
		}

		const data = await result.json();
		return data;
	} catch (error) {
		throw error;
	}
};

const getAllImages = async (jwtToken, userData, page = 0, size = 6) => {
	try {
		const result = await Requests.getRequest(
			`${baseUrlList}?page=${page}&size=${size}`,
			jwtToken,
			userData
		);
		if (!result || !result.ok) {
			throw new Error('Unable to fetch images');
		}
		return await result.json();
	} catch (error) {
		console.error('Error fetching images:', error);
		return [];
	}
};

const getTotalPages = async (jwtToken, userData, pageSize = 6) => {
	try {
		const result = await Requests.getRequest(
			`${baseUrlList}?page=0&size=1`,
			jwtToken,
			userData
		);
		if (!result || !result.ok) {
			throw new Error('Unable to fetch total pages');
		}
		const data = await result.json();
		const totalImages = data.totalElements; // Assuming `totalElements` contains total images
		const totalPages = Math.ceil(totalImages / pageSize);
		return totalPages;
	} catch (error) {
		console.error('Error fetching total pages:', error);
		return 0;
	}
};

const searchImages = async (
	jwtToken,
	userData,
	searchTerm,
	page = 0,
	size = 1000
) => {
	try {
		const result = await Requests.getRequest(
			`${baseUrlList}?page=${page}&size=${size}&searchText=${encodeURIComponent(
				searchTerm
			)}`,
			jwtToken,
			userData
		);
		if (!result.ok) {
			throw new Error('Unable to search images');
		}
		return await result.json();
	} catch (error) {
		console.error('Error searching images:', error);
		throw error;
	}
};

const handleGenerateSubmit = async (
	event,
	generateImage,
	textValue,
	selectedOption,
	selectedValue,
	setImageData,
	setIsGenerateFormSubmitted,
	setErrorMessage
) => {
	event.preventDefault();
	setImageData(null);
	setIsGenerateFormSubmitted(true);

	const combinedText = ` ${selectedOption} ${textValue}`;

	try {
		const generatedImage = await generateImage({
			prompt: combinedText,
			imageDimension: selectedValue,
		});

		if (generatedImage.status === 'FAIL') {
			setErrorMessage(`Image generation failed: ${generatedImage.error}`);
			setIsGenerateFormSubmitted(false);
			return;
		}

		setImageData(generatedImage);
	} catch (error) {
		setErrorMessage(
			'An error occurred while generating the image. Please try again.'
		);
	} finally {
		setIsGenerateFormSubmitted(false);
	}
};

const handleRegenerateSubmit = async (
	event,
	generateImage,
	imageRegularData,
	setImageData,
	setIsGenerateFormSubmitted,
	setErrorMessage
) => {
	event.preventDefault();
	setImageData(null);
	setIsGenerateFormSubmitted(true);

	try {
		const generatedImage = await generateImage({
			prompt: imageRegularData,
			imageDimension: '1792x1024',
		});

		if (generatedImage.status === 'FAIL') {
			setErrorMessage(`Image regeneration failed: ${generatedImage.error}`);
			setIsGenerateFormSubmitted(false);
			return;
		}

		setImageData(generatedImage);
	} catch (error) {
		console.error('Error in handleRegenerateSubmit:', error);
		setErrorMessage(
			`An error occurred while regenerating the image: ${error.message}`
		);
	} finally {
		setIsGenerateFormSubmitted(false);
	}
};

const handleDownload = (selectedImage) => {
	if (!selectedImage) {
		alert('No image selected for download.');
		return;
	}

	let base64Data = selectedImage.base64_medium;

	if (!base64Data) {
		alert('Unable to download: Image data is missing.');
		return;
	}

	let filename = selectedImage.filename;
	let mimeType = 'image/jpeg';

	if (!filename) {
		filename = `image-${selectedImage.imageID}.jpg`;
	} else if (
		typeof filename === 'string' &&
		filename.toLowerCase().endsWith('.png')
	) {
		mimeType = 'image/png';
	}

	try {
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: mimeType });

		const url = URL.createObjectURL(blob);
		const downloadLink = document.createElement('a');
		downloadLink.href = url;
		downloadLink.download = filename;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);

		URL.revokeObjectURL(url);
	} catch (error) {
		console.error('Error during download:', error);
		alert('An error occurred while downloading the image.');
	}
};

const handleCopyPrompt = (selectedImage) => {
	if (selectedImage) {
		navigator.clipboard
			.writeText(selectedImage.promptUsed)
			.then(() => {
				alert('Prompt copied to clipboard');
			})
			.catch((err) => {
				console.error('Failed to copy prompt: ', err);
			});
	}
};

const getImageById = async (jwtToken, userData, imageId) => {
	if (!userData) {
		throw new Error('User data is missing. Please log in again.');
	}
	try {
		const imageData = await getBase64Image(imageId, jwtToken, userData);

		if (!imageData) {
			throw new Error('Image data is null or undefined');
		}

		const base64Data =
			imageData.base64 || imageData.base64_medium || imageData.base64_thumbnail;

		if (!base64Data) {
			throw new Error('Image data does not contain expected base64 property');
		}

		return { ...imageData, base64: base64Data };
	} catch (error) {
		throw error;
	}
};

const deleteImage = async (imageId, userData, refreshToken) => {
	const url = `${baseUrlDelete}/${imageId}`;

	try {
		const imageExists = await ImageService.getImageById(
			userData.jwtToken,
			userData,
			imageId
		);

		console.log('Image exists:', imageExists);
		if (!imageExists) {
			throw new Error('Image does not exist.');
		}

		const result = await Requests.deleteRequest(url, userData, refreshToken);

		if (!result.ok) {
			const errorData = await result.json();
			throw new Error(
				`Unable to delete image: ${errorData.message || result.statusText}`
			);
		}

		return { success: true, result: result };
	} catch (error) {
		throw error;
	}
};

const ImageService = {
	getImageStream,
	generateImage,
	getBase64Image,
	getAllImages,
	deleteImage,
	handleGenerateSubmit,
	handleRegenerateSubmit,
	handleDownload,
	handleCopyPrompt,
	getTotalPages,
	searchImages,
	getImageById,
};

export default ImageService;
