import 'bootstrap/dist/css/bootstrap.min.css';
import './react-datetime.css';
import { useContext, useEffect, useState } from 'react';
import Home from './components/Home';
import Login from './components/login/Login';
import UserData from './components/services/UserData';
import UserService from './components/services/UserService';
import AuthService from './components/services/AuthService';
import Spinner from './components/shared_components/Spinner/Spinner';

function App() {
  const userData = useContext(UserData);
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  const logOut = () => {
    AuthService.logout(userData).then(() => {      
      UserService.logOutUser(userData);
      setAuthorized(false);
    }).catch(error => {
      console.error(error);
      UserService.logOutUser(userData);
      setAuthorized(false);
    })
  }

  const setAuthorizedStatus = (isAuthorized) => {
    setAuthorized(isAuthorized);
    setLoading(false);
  }  

  useEffect(() => {
    setAuthorizedStatus(UserService.checkUserLoggedIn(userData));
  }, [userData]);

  const getStartView = () => {
    if (loading) {
      return <Spinner />
    }

    return (authorized) ? <Home logOut={logOut}/> : <Login setAuthorized={setAuthorizedStatus}/>;
  }

  const startView = getStartView();

  return (
    startView
  );
}

export default App;
