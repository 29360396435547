import ENV from "./Environment";
import Requests from "./Requests";

const baseUrl = `${ENV.base_url}/api/v1/glossary/list`;

const getPluginGlossaryList = async(userData) => {
  const result = await Requests.getRequest(baseUrl, userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    return new Error(getError(data));
  }
  return data;
}

const getError = (data) => {
  if (data.error === undefined || data.error === null) {
    return "An Unexpected error occurred.";
  }
  return data.error;
}

const PluginGlossaryService = {
  getPluginGlossaryList
}

export default PluginGlossaryService;