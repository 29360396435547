import { NavLink } from 'react-router-dom';
import MenuItemStyle from './MenuItem.module.css';
import UserData from '../../services/UserData';
import { useContext } from 'react';
import UserClickTracker from '../../services/UserClickTracker';

const MenuItem = ({ children, path }) => {
  const userData = useContext(UserData);

  const udpateUserClick = () => {
    UserClickTracker.registerClick(userData);
  }

  const getActiveLink = ({isActive}) => {
    const activeClass = isActive ? MenuItemStyle.active : null;
    return `${MenuItemStyle.menu} ${activeClass}`;
  }

  return (
    <NavLink 
      className={getActiveLink}
      onClick={udpateUserClick}
      to={path}
    >{children}</NavLink>
  )
}

export default MenuItem;