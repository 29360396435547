import AuditInformationLine from "../AuditInformationLine/AuditInformationLine";

const AuditLogsListHeadings = ({setSortDirectionSelection, sortDirection}) => {
    const auditHeaders = {
        timeStamp: "TimeStamp",
        username: "Username",
        eventType: "Event Type",
        eventAction: "Event Action",
        description: "Description"
    }
    return (
        <AuditInformationLine
            auditData={auditHeaders}
            header={true}
            setSortDirectionSelection={setSortDirectionSelection}
            sortDirection={sortDirection}
        />
    )
}

export default AuditLogsListHeadings;