import Pagination from "react-bootstrap/Pagination";
import PaginationGroupStyle from './PaginationGroup.module.css';
import UserData from "../../services/UserData";
import { useContext } from "react";
import UserClickTracker from "../../services/UserClickTracker";

const PaginationGroup = ({ activePage, numberOfPages, updatePageClicked }) => {
  const userData = useContext(UserData);
  const maxNumber = 10;

  const getMaxIndex = (startFrom) => {
    if (startFrom + 9 > numberOfPages) {
      return numberOfPages;
    }
    return startFrom + 9;
  }

  const updateUserClick = (selectedPage) => {
    UserClickTracker.registerClick(userData);
    updatePageClicked(selectedPage);
  }

  const getPages = (startFrom) => {
    const maxIndex = getMaxIndex(startFrom);
    const pageArray = [];
    if (startFrom > 1) {
      pageArray.push(
        <Pagination.Item
          key='page_first'
          onClick={() => updateUserClick(1)}>
            First
        </Pagination.Item>
      );
    }
    for (let x = startFrom; x <= maxIndex; x++) {
      const isActive = +activePage === x;
      pageArray.push(
        <Pagination.Item 
          active={isActive} 
          key={`page-${x}`}
          onClick={() => updateUserClick(x)}
        >{x}</Pagination.Item>
      );
    }
    if (maxIndex < numberOfPages) {
      pageArray.push(
        <Pagination.Item
          key='page_last'
          onClick={() => updateUserClick(numberOfPages)}>
            Last
        </Pagination.Item>
      );
    }
    return pageArray;
  }

  const getStartIndex = () => {
    let startIndex = activePage - 4;

    if (startIndex < 1) {
      return 1;
    }

    if (numberOfPages - getMaxIndex(startIndex) === 0) {
      return numberOfPages - 9;
    }

    return startIndex;
  }

  const getPagination = () => {
    if (numberOfPages <= maxNumber) {
      return getPages(1);
    }

    const startFromIndex = getStartIndex();
    return getPages(startFromIndex);
  }

  const pagination = getPagination();

  return (
    <div className={PaginationGroupStyle.main}>
      <Pagination>
        {pagination}
      </Pagination>
    </div>
  )
}

export default PaginationGroup;