import style from "./EditRoleGroup.module.css";
import UserRight from "./UserRight/UserRight";

const EditRoleGroup = ({user, role}) => {    
    const roles = role.roles.sort();

    return (
        <div className={style.main}>
            <div className={style.role_group}>
                {role.group}
                {roles.map(oneRole => <UserRight key={`${oneRole}`} user={user}>{oneRole}</UserRight>)}
            </div>
        </div>
    )
}

export default EditRoleGroup;