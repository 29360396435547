import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import style from "./EssayTextEdit.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";

const EssayTextEdit = ({question, helpMessage, formKey, formData}) => {
    const [value, setValue] = useState(formData[formKey]);

    const updateFormData = (input) => {
        const formValue = input.target.value;
        formData[formKey] = formValue;
        setValue(formValue);
    }

    return (
        <div key={question} className={style.main}>
            <div className={style.question}>{question}</div>
            <div className={style.text_input}><input type="text" name={formKey} onChange={updateFormData} value={value}/></div>
            <div className={style.tooltip}>
                <OverlayTrigger placement="left" overlay={<Tooltip id={question}>{helpMessage}</Tooltip>}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default EssayTextEdit;