import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import style from './CountdownTimer.module.css';

const CountdownTimer = ({ initialSeconds }) => {
	const renderTime = ({ remainingTime }) => {
		if (remainingTime === 0) {
			return <div className={style.countdownText}>Time's up!</div>;
		}

		return <div className={style.countdownText}></div>;
	};

	return (
		<div className={style.countdownContainer}>
			<CountdownCircleTimer
				isPlaying
				duration={initialSeconds}
				colors={['#ec971f']}
				trailColor='#d6d6d6'
				onComplete={() => ({ shouldRepeat: false })}>
				{renderTime}
			</CountdownCircleTimer>
		</div>
	);
};

export default CountdownTimer;
