import style from './AssetItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Utils from '../../utils/Utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import UserData from '../../services/UserData';
import { useContext } from 'react';
import UserClickTracker from '../../services/UserClickTracker';

const AssetItem = ({data, referrer, link, assetType, heading }) => {
  const navigate = useNavigate();
  const userData = useContext(UserData);

  const isHeading = Utils.isNull(heading) ? false : heading;

  const showPluginDetails = () => {
    UserClickTracker.registerClick(userData);
    navigate(link, { state: {referrer, asset: data, assetType: assetType} });
  }
  

  const getEditIcon = () => {
    if (Utils.userIsViewOnly(userData)) {
      return (
        <OverlayTrigger placement="left" overlay={<Tooltip id={link}>View</Tooltip>}>
        <FontAwesomeIcon onClick={showPluginDetails} icon={faEye} />
      </OverlayTrigger>        
      )
    }

    return (
      <OverlayTrigger placement="left" overlay={<Tooltip id={link}>Edit</Tooltip>}>
        <FontAwesomeIcon onClick={showPluginDetails} icon={faPen} />
      </OverlayTrigger>
    )
  }

  const headingStyle = isHeading ? style.heading : null;

  const getIconHeader = () => {
    return Utils.userIsViewOnly(userData) ? "VIEW" : "EDIT";
  }

  const fontIcon = isHeading ? <span>{getIconHeader()}</span> : getEditIcon();

  return (
    <div className={`${style.plugin_view} ${headingStyle}`}>
      <span>{data.name}</span>
      <span>{data.pluginType}</span>
      <span>{data.lastUpdateBy}</span>
      <div>{fontIcon}</div>
    </div>
  )
}

export default AssetItem;
