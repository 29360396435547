import Utils from "../utils/Utils";

const extractHRefLinks = (html) => {
  const indexTable = html.current.querySelector(".WordSection1 > table:first-child > tbody");
  return populateRefLink(indexTable);
}

const getHeadingLink = (tableRow) => {
  const link = tableRow.querySelector("a");
  if (Utils.isNull(link)) {
    return null;
  }
  return { name: tableRow.innerText, link: link.href };
}

const populateRefLink = (indexTable) => {
  const allTableRows = [...indexTable.querySelectorAll("tr > td:nth-child(2)")];    
  return allTableRows.map(tableRow => getHeadingLink(tableRow));
}

const HtmlLinkExtrator = {
  extractHRefLinks
}

export default HtmlLinkExtrator;