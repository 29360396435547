import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './WhiteMainBackgroundWithBackButton.module.css';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

const WhiteMainBackgroundWithBackButton = ({children, heading, backButtonAction}) => {
  return (
    <div className={style.main}>
      <div className={style.heading}>
        <FontAwesomeIcon className={style.back_button} icon={faCircleChevronLeft} onClick={backButtonAction} />
        <span>{heading}</span>
      </div>
      {children}
    </div>
  )
}

export default WhiteMainBackgroundWithBackButton;