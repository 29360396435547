import style from './ItemList.module.css';

const ItemList = ({children}) => {
  return (
    <div className={style.list}>
      {children}
    </div>
  )
}

export default ItemList;