import { useContext, useEffect, useState } from "react";
import ListContainer from "../../../../shared_components/ListContainer/ListContainer";
import ListHeadingWithSearch from "../../../../shared_components/ListHeadingWithSearch/ListHeadingWithSearch";
import PaginationGroup from "../../../../shared_components/PaginationGroup/PaginationGroup";
import VersionControlService from "../../../../services/VersionControlService";
import UserData from "../../../../services/UserData";
import Utils from "../../../../utils/Utils";
import InfoPage from "../../../InfoPage/InfoPage";
import Spinner from "../../../../shared_components/Spinner/Spinner";
import ItemList from "../../../../shared_components/ItemList/ItemList";
import VersionHistoryItem from "./VersionHistoryItem/VersionHistoryItem";

const VersionHistory = () => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const userData = useContext(UserData);
    const [error, setErrorMessage] = useState(null);
    const [versionHistoryList, setVersionHistoryList] = useState(null);
    const [searchText, setSearchText] = useState("");

    const searchCommitHistory = (userSearchText) => {
        setPage(1);
        setErrorMessage(null);
        setSearchText(userSearchText);
    }

    const getVersionHistoryList = () => {
        const versionHistoryItemHeading = {
            created: "Created",
            commit: "Commit/Image",
            mainCommitReference: "Main",
            reason: "Info",
            version: "Version"
        }
        return (
            <ItemList>
                <VersionHistoryItem versionHistoryItem={versionHistoryItemHeading} heading={true} />
                <div>
                    {versionHistoryList.map(vh => <VersionHistoryItem key={`vh_${vh.commit}`} versionHistoryItem={vh} heading={false} />)}
                </div>
            </ItemList>
        )
    }

    const getUserView = () => {
        if (!Utils.isNull(error)) {
            return <InfoPage description={error} reason="Unable to retrieve version history."/>;
        }
        if (Utils.isNull(versionHistoryList)) {
            return <Spinner />
        }
        return getVersionHistoryList();
    }

    const updatePageSelect = (pageSelected) => {
        setPage(pageSelected);
    }

    const getPagination = () => {
        if (totalPages === 0) {
            return null;
        }
        return <PaginationGroup activePage={page} numberOfPages={totalPages} updatePageClicked={updatePageSelect} />
    }

    useEffect(() => {
        VersionControlService.getVersionHistory(userData, page, searchText).then(data => {
            setVersionHistoryList(data.content);
            setTotalPages(data.totalPages);
        }).catch(error => {
            setErrorMessage(error.message);
        })
    }, [userData, page, searchText])

    return (
        <ListContainer>
            <ListHeadingWithSearch searchAction={searchCommitHistory}>VERSION HISTORY</ListHeadingWithSearch>
            {getUserView()}
            {getPagination()}
        </ListContainer>
    )
}

export default VersionHistory;