import { useState, useEffect, useRef, useCallback } from 'react';
import ImageService from '../../../services/ImageService';

const usePolling = (
	imageID,
	userData,
	setImageData,
	setImageRegularData,
	setTextAreaIsDisabled,
	setIsLoading,
	setErrorMessage
) => {
	const STATUS_SUCCESS = 'SUCCESS';
	const STATUS_FAIL = 'FAIL';
	const POLL_INTERVAL = 3000;

	const [hasError, setHasError] = useState(false);
	const pollingRef = useRef(null);

	const handleError = useCallback(
		(error) => {
			console.error('Error in image generation:', error);
			setErrorMessage(
				error.message || 'Could not generate image. Please try again.'
			);
			setIsLoading(false);
			setHasError(true);
		},
		[setErrorMessage, setIsLoading]
	);

	useEffect(() => {
		if (!imageID || hasError) return;

		const getImageStatus = async (imageID) => {
			return await ImageService.getImageStream(
				imageID,
				userData.jwtToken,
				userData
			);
		};

		const waitForCompletion = async () => {
			try {
				const statusResponse = await getImageStatus(imageID);
				if (statusResponse.status === STATUS_SUCCESS) {
					const getBase64Image = await ImageService.getBase64Image(
						imageID,
						userData.jwtToken,
						userData
					);
					setImageData(getBase64Image);
					setImageRegularData(getBase64Image.revisedPrompt);
					setTextAreaIsDisabled(false);
					setIsLoading(false);
					return true;
				} else if (statusResponse.status === STATUS_FAIL) {
					throw new Error('Image generation failed. Please try again.');
				}
			} catch (error) {
				handleError(error);
				return true;
			}
			return false;
		};

		const poll = async () => {
			if (hasError) return;

			const generationComplete = await waitForCompletion();
			if (!generationComplete && !hasError) {
				pollingRef.current = setTimeout(poll, POLL_INTERVAL);
			}
		};

		poll();

		return () => {
			if (pollingRef.current) {
				clearTimeout(pollingRef.current);
			}
		};
	}, [
		imageID,
		userData,
		setImageData,
		setImageRegularData,
		setTextAreaIsDisabled,
		setIsLoading,
		handleError,
		hasError,
	]);

	return null;
};

export default usePolling;
