import style from "./AITeacherResponse.module.css";

const AITeacherResponse = ({response}) => {
    return (
        <div className={style.main}>
            <div className={style.header}>AI Response</div>
            <div>{response}</div>
        </div>
    )
}

export default AITeacherResponse;