import style from './ListheadingWithSearch.module.css';
import SearchButton from '../SearchButton/SearchButton';

const ListHeadingWithSearch = ({ children: listHeading, searchAction }) => {
  return (
    <div className={style.main}>
      <div className={style.heading}>{listHeading}</div>
      <div>
        <SearchButton placeholder={"SEARCH"} searchAction={searchAction}/>
      </div>
    </div>
  )
}

export default ListHeadingWithSearch;