const isNull = (value) => {
  return (value === null || value === undefined);
}

const isEmpty = (value) => {
  return (isNull(value) || value.length === 0);
}

const getFromLocationOrDefault = (key, location, defaultValue) => {
  if (location.state === null || location.state[key] === null) {
    return defaultValue;
  }
  return location.state[key];
}

const getUsefulError = (data) => {
  if (data.error === undefined || data.error === null) {
    return "Unexpected error";
  }
  return data.error;
}

const getErrorFromRequest = (data) => {
  return getUsefulError(data);
}

const sanitizeText = (text) => {
  return text;
}

const userIsViewOnly = (userData) => {
  return (userData.roles.length === 1 && userData.roles.includes("view")); 
}

const Utils = {
  isNull,
  isEmpty,
  getFromLocationOrDefault,
  getUsefulError,
  getErrorFromRequest,
  sanitizeText,
  userIsViewOnly
}

export default Utils;