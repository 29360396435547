import style from "./UserRoleItem.module.css";
import StyledButton from "../../../../../shared_components/StyledButton/StyledButton";
import { Badge, Stack } from "react-bootstrap";

const UserRoleItem = ({userItem, heading, editUser}) => {
    const getActionView = () => {
        if (heading) {
            return <span>Edit</span>;
        }

        return <StyledButton clickEvent={() => editUser(userItem)}>EDIT</StyledButton>
    }

    const getLineStyle = () => {
        return heading ? style.heading : null;
    }

    const getListItemForUser = () => {
        if (heading) {
            return <span>{userItem.role}</span>
        }

        return (
            <Stack direction="horizontal" gap={2}>
                {userItem.roles.map(role => <Badge key={`${userItem.username}_${role}`} bg="primary">{role}</Badge>)}
            </Stack>            
        )
    }

    return (
        <div className={`${style.user_container} ${getLineStyle()}`}>
            <span>{userItem.username}</span>
            {getListItemForUser()}
            {getActionView()}
        </div>
    );
}

export default UserRoleItem;