import Utils from "../utils/Utils";
import ENV from "./Environment";
import Requests from "./Requests";

const baseUrl = `${ENV.base_url}/api/v1/cred`;

const getAccessHistoryList = async(userData, page, searchText) => {
  const url = `${baseUrl}/login/list?page=${page - 1}&searchText=${Utils.sanitizeText(searchText)}`
  const result = await Requests.getRequest(url, userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    return new Error(Utils.getErrorFromRequest(data));
  }
  return data;
}

const logout = async (userData) => {
  const url = `${baseUrl}/logout`;
  const result = await Requests.getRequest(url, userData.jwtToken, userData);
  const data =  await result.clone().json();
  return data;
}

const disableUser = async (userData, username) => {
  const url = `${baseUrl}/disable/user`;
  const requestBody = { username, reason: "Deactivate" };
  return userRequest(url, requestBody, userData);
}

const enableUser = async (userData, username) => {
  const url = `${baseUrl}/enable/user`;
  const requestBody = { username, reason: "Activate" };
  return userRequest(url, requestBody, userData);
}

const userRequest = async (url, requestBody, userData) => {
  const result = await Requests.postRequest(url, userData.jwtToken, requestBody, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    return new Error(Utils.getErrorFromRequest(data));
  }
  return data;
}

const AuthService = {
  getAccessHistoryList,
  logout,
  enableUser,
  disableUser
}

export default AuthService;
