import MenuItem from "../../../Menu/MenuItem/MenuItem"
import Heading from "../../../shared_components/Heading/Heading"
import SubMenuContainer from "../../../shared_components/SubMenuContainer/SubMenuContainer"

const SettingsMenu = () => {
    return (
        <SubMenuContainer>
            <Heading>Settings</Heading>
            <MenuItem path="version-control">Version Control</MenuItem>
            <MenuItem path="version-history">Version History</MenuItem>
        </SubMenuContainer>
    )
}

export default SettingsMenu;