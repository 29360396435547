import moment from 'moment';
import Utils from '../../../../utils/Utils';
import style from './AssetDetailLine.module.css';
import SpreadsheetLine from './SpreadsheetLine/SpreadsheetLine';

const AssetDetailLine = ({ term, value, isTime, isSpreadsheet, assetDetails }) => {

  const getFormattedValue = () => {
    if (!Utils.isNull(isTime) && isTime) {      
      return moment(value).format('D MMMM YYYY, h:mm a');
    }

    if (!Utils.isNull(isSpreadsheet) && isSpreadsheet) {
      return <SpreadsheetLine spreadsheetName={value} assetDetails={assetDetails} />
    }

    return value;
  }

  const formattedValue = getFormattedValue();

  return (
    <div className={style.main}>
      <div className={style.heading}>{term}</div>
      <div>{formattedValue}</div>
    </div>
  )
}

export default AssetDetailLine;