import style from "./SubMenuAndMainContainer.module.css";

const SubMenuAndMainContainer = ({children}) => {
  return (
    <div className={style.children_container}>
      {children}
    </div>
  )
}

export default SubMenuAndMainContainer;