import { useContext, useEffect, useState } from "react";
import UserData from "../../../../services/UserData";
import ListContainer from "../../../../shared_components/ListContainer/ListContainer";
import ListHeadingWithSearch from "../../../../shared_components/ListHeadingWithSearch/ListHeadingWithSearch";
import Utils from "../../../../utils/Utils";
import InfoPage from "../../../InfoPage/InfoPage";
import Spinner from "../../../../shared_components/Spinner/Spinner";
import ItemList from "../../../../shared_components/ItemList/ItemList";
import PaginationGroup from "../../../../shared_components/PaginationGroup/PaginationGroup";
import { useParams } from "react-router-dom";
import UserService from "../../../../services/UserService";
import UserRoleItem from "./UserRoleItem/UserRoleItem";
import RolesService from "../../../../services/RolesService";
import EditUserRoles from "./EditUserRoles/EditUserRoles";

const UserRoles = () => {
    const { page } = useParams();
    const userData = useContext(UserData);
    const [searchText, setSearchText ] = useState("");
    const [ showList, setShowList ] = useState(true);
    const safePage = Utils.isNull(page) ? 1 : page;
    const [ error, setError ] = useState(null);
    const [ userList, setUserList ] = useState(null);
    const [ userListVersion, setUserListVersion ] = useState(0);
    const [ pages, setPages ] = useState(0);
    const [ activePage, setActivePage ] = useState(safePage);
    const [ selectedUser, setSelectedUser ] = useState(null);
    const [ roles, setRoles ] = useState(null);

    const searchUsers = (searchTextFromTextBox) => {
        setSearchText(searchTextFromTextBox);
        setActivePage(1);
    }

    useEffect(() => {
        UserService.getUsers(userData, activePage, searchText).then(data => {
            setUserList(data.content);
            setPages(data.totalPages);
        }).catch(error => {
            setError(error.message);
        })
        RolesService.getRoles(userData).then(data => {
            setRoles(data);
        }).catch(error => {
            setError(error.message);
        })
    }, [userData, activePage, searchText, userListVersion]);

    const getHeadings = () => {
        return {
            username: "Username",
            role: "Roles",
            active: "Status"
        }
    }

    const updateUserListVersion = () => {
        setUserListVersion(old => old + 1);
    }

    const getUsersListView = () => {
        return (
            <ItemList>
                <UserRoleItem 
                    userItem={getHeadings()}
                    heading={true}
                />
                <div>
                    {getList()}
                </div>
            </ItemList>
        )
    }

    const getUserView = () => {
        if (!Utils.isNull(error)) {
            return <InfoPage description={error} reason="Unable to retrieve users." />;
        }

        if (Utils.isNull(userList)) {
            return <Spinner />
        }

        return getUsersListView();
    }

    const updatePageClicked = (page) => {
        setActivePage(page);
    }

    const getPagination = () => {
        if (pages === 1) {
            return null;
        }
        return <PaginationGroup activePage={activePage} numberOfPages={pages} updatePageClicked={updatePageClicked}/>
    }

    const showUser = (user) => {
        setSelectedUser(user);
        setShowList(false);
    }

    const getList = () => {
        return userList
            .map(userItem => <UserRoleItem key={userItem.id} userItem={userItem} updateList={updateUserListVersion} editUser={showUser}/>);
    }

    const listView = (
        <ListContainer>
            <ListHeadingWithSearch searchAction={searchUsers}>User roles</ListHeadingWithSearch>
            {getUserView()}
            {getPagination()}
        </ListContainer>
    )

    const goBack = () => {
        setShowList(true);
    }    

    const userView = (user) => {
        return <EditUserRoles user={user} roles={roles} backButtonAction={goBack} />
    }

    return (
        showList ? listView : userView(selectedUser)
    )
}

export default UserRoles;