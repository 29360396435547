import ENV from './Environment';

const baseUrl = `${ENV.base_url}/api/v1/upload/files`;

const uploadFiles = async (files, guid, jwtToken) => {
  const formData = new FormData();
   
  for (let i = 0; i < files.length; i++) {
    formData.append('file', files[i]);
  }
  
  const result = await makePostRequest(formData, guid, jwtToken);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const getError = (data) => {
  if (data.error === null || data.error === undefined) {
    return "Unexpted error please try again later.";
  }
  return data.error;
}

const makePostRequest = async (formData, guid, jwtToken) => {
  const uploadRequest = guid === null ? baseUrl : `${baseUrl}/${guid}`;
  return await fetch(uploadRequest, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    body: formData
  })
}

const UploadService = {
  uploadFiles: uploadFiles
}

export default UploadService;