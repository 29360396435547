import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./AuditInformationLine.module.css";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const AuditInformationLine = ({auditData, header, setSortDirectionSelection, sortDirection}) => {
    const headerClass = header ? style.header : null;


    const sortUserDirection = () => {
        setSortDirectionSelection(sortDirection === "DESC" ? "ASC" : "DESC");
    }

    const downloadIcon = () => {
        if (!header) {
            return null;
        }

        const iconType = sortDirection === "DESC" ? faArrowUp : faArrowDown;

        return <span><FontAwesomeIcon icon={iconType} onClick={sortUserDirection}/></span>
    }

    return (
        <div className={`${style.main} ${headerClass}`}>
            <div className={style.sort}>{downloadIcon()}<span>{auditData.timeStamp}</span></div>
            <div>{auditData.username}</div>
            <div>{auditData.eventType}</div>
            <div>{auditData.eventAction}</div>
            <div>{auditData.description}</div>
        </div>
    )
}

export default AuditInformationLine;