import style from './ErrorBanner.module.css';

const ErrorBanner = ({ message, onDismiss }) => {
	return (
		<div className={style.errorBanner}>
			<p>{message}</p>
			<button onClick={onDismiss}>Dismiss</button>
		</div>
	);
};

export default ErrorBanner;
