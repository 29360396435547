import Utils from '../utils/Utils';
import ENV from './Environment';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1/version-control`;

const getVersionInformation = async(userData) => {
  const result = await Requests.getRequest(`${baseUrl}/info`, userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const updateMajorOrMinorVersion = async(userData, updateData) => {
  const result = await Requests.postRequest(`${baseUrl}/update-major-or-minor`, userData.jwtToken, updateData, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const getVersionHistory = async(userData, page, searchText) => {
  const url = new URL(`${baseUrl}/version-history`);
  url.searchParams.append("searchText", Utils.sanitizeText(searchText));
  url.searchParams.append("page", page);
  const result = await Requests.getRequest(url.toString(), userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const getError = (data) => {
  if (data.error === undefined || data.error === null) {
    return "An Unexpected error occurred.";
  }
  return data.error;
}


const VersionControlService = {
  getVersionInformation,
  updateMajorOrMinorVersion,
  getVersionHistory
}

export default VersionControlService;