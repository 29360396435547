import { useState } from "react";
import DateTimePicker from "./DateTimePicker/DateTimePicker";
import style from "./DateTimeRange.module.css";

const DateTimeRange = ({setFilterByDateValue, setDateTimeBetween, dateTimeBetween}) => {
    const [showDateTimePicker, setDateTimePickerVisibility] = useState(false);

    const updateTimeRangeSelect = (event) => {
        const timeRangeSelection = event.target.value === "any" ? "" : event.target.value;
        setFilterByDateValue(timeRangeSelection);
        setDateTimePickerVisibility(event.target.value === "between");
    }

    const showStyle = (showDateTimePicker) ? style.show : style.hide;

    return (
        <div className={style.main}>
            <select onChange={updateTimeRangeSelect}>
                <option value="any">No time range</option>
                <option value="between">Between range</option>
            </select>
            <div className={showStyle}>
                <span>From:</span>
                <DateTimePicker setDateTimeBetween={setDateTimeBetween} dateTimeBetween={dateTimeBetween} dateFrom={true} />
            </div>
            <div className={showStyle}>
                <span>To:</span>
                <DateTimePicker setDateTimeBetween={setDateTimeBetween} dateTimeBetween={dateTimeBetween} dateFrom={false} />
            </div>
        </div>
    )
}

export default DateTimeRange;