import Utils from "../../../../../utils/Utils";
import style from "./AIMarkingHeaderLine.module.css";
import AIMarkingLinePartition from "./AIMarkingLinePartition/AIMarkingLinePartition";

const AIMarkingHeaderLine = ({
    firstHeading, secondHeading, firstContent, secondContent
}) => {

    return (
        <div className={style.main}>
            {Utils.isNull(firstHeading) ? null : <AIMarkingLinePartition heading={firstHeading} content={firstContent}/>}
            {Utils.isNull(secondHeading) ? null : <AIMarkingLinePartition heading={secondHeading} content={secondContent}/>}
        </div>
    )
}

export default AIMarkingHeaderLine;