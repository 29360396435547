import Datetime from "react-datetime";
import Utils from "../../../../../../../utils/Utils";
import LoadingPulse from "../../../../../../../shared_components/LoadingPulse/LoadingPulse";
import moment from "moment/moment";

const DateTimePicker = ({setDateTimeBetween, dateTimeBetween, dateFrom}) => {

    const getDefaultDateTime = () => {
        if (dateFrom) {
            return new Date(dateTimeBetween.from);
        }
        return new Date(dateTimeBetween.to);
    }

    const updateDateTimeChange = (value) => {
        const keyToChange = dateFrom ? "from" : "to";
        const updateValues = { ...dateTimeBetween };
        const utcDateTime = moment(value);
        const secondsValue = dateFrom ? 0 : 59;
        utcDateTime.set({second: secondsValue});
        updateValues[keyToChange] = utcDateTime.format();
        setDateTimeBetween(updateValues);
    }

    const validDates = (current) => {
        const fromDate = moment(dateTimeBetween.from).subtract(1, 'day');
        const toDate = moment(dateTimeBetween.to).add(1, 'day');
        return current.isAfter(fromDate) && current.isBefore(toDate);
    }

    const getView = () => {
        if (Utils.isNull(dateTimeBetween)) {
            return <LoadingPulse />
        }
         
        return (
            <Datetime
                initialValue={getDefaultDateTime()}
                onClose={updateDateTimeChange}
                isValidDate={validDates}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
            />
        )
    }

    return (
        getView()
    )
}

export default DateTimePicker;