import { useState } from "react";
import style from "./UserRight.module.css";

const UserRight = ({user, children}) => {
    const [ userHasRight, setUserHasRight ] = useState(user.roles.find(role => role.toLowerCase() === children.toLowerCase()) !== undefined);
    const friendlyRoleName = children.substring(children.lastIndexOf("_") + 1);

    const roleIndex = (name) => {
        return user.roles.findIndex(role => role.toLowerCase() === name.toLowerCase());
    }

    const addRoleToUser = (name) => {
        if (roleIndex(name) === -1) {
            user.roles.push(name);
        }
    }

    const removeRoleFromUser = (name) => {
        const roleInd = roleIndex(name);
        if (roleInd !== -1) {
            user.roles.splice(roleInd, 1);            
        }
    }

    const roleChanged = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        if (checked) {
            addRoleToUser(name);
        } else {
            removeRoleFromUser(name);
        }
        setUserHasRight(roleIndex(name) !== -1);
    }

    return (
       <div className={style.user_right}>
            <div>
                {friendlyRoleName}
            </div>
            <div>
                <input type="checkbox" name={children} checked={userHasRight} onChange={roleChanged} />
            </div>
        </div> 
    )
}

export default UserRight;