import ViewAudio from './ViewAudio/ViewAudio';
import { useParams, useNavigate } from 'react-router-dom';
import Utils from '../../utils/Utils';

const AudioHome = () => {
  const navigate = useNavigate();
  const { page } = useParams();

  const activePage = Utils.isNull(page) ? 1 : page;

  const updateView = () => {
    navigate('/asset/audio', { state: { referrer: `/audio/${activePage}`}});
  }

  return (
    <ViewAudio updateView={updateView} activePage={activePage} />
  )
}

export default AudioHome;