import SearchButton from '../../../../shared_components/SearchButton/SearchButton';
import style from './SearchMenu.module.css';

const SearchMenu = ({searchAction}) => {
  return (
    <div className={style.search_menu}>
      <SearchButton placeholder="Search Document" searchAction={searchAction} />
    </div>
  )
}

export default SearchMenu;