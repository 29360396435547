import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";

const SearchButton = ({placeholder, searchAction}) => {
  const [searchText, updateSearchText] = useState("");
  const [clearSearch, updateClearSearch] = useState(false);

  const updateChange = (e) => {
    updateSearchText(e.target.value);
    updateClearSearch(false);
  }

  const searchClicked = () => {
    if (clearSearch) {
      updateSearchText("");
      searchAction("");
      updateClearSearch(false);
    } else {
      searchAction(searchText);
      updateClearSearch(true);
    }
  }

  const checkKey = (event) => {
    if (event.keyCode === 13) {
      searchClicked();
    }
  }

  const iconToShow = clearSearch ? faClose : faSearch;

  return (
    <InputGroup>
      <FormControl
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="button-search"
        value={searchText}
        onChange={updateChange}
        onSubmit={searchClicked}
        onKeyUp={checkKey}
      />
      <Button variant="outline-secondary" id="button-search" onClick={searchClicked} >
        <FontAwesomeIcon icon={iconToShow} />
      </Button>
    </InputGroup>
  )
}

export default SearchButton;