import PluginsStyle from './ViewPlugins.module.css';
import AssetItem from '../../../shared_components/AssetItem/AssetItem';
import ItemList from '../../../shared_components/ItemList/ItemList';
import { useCallback, useContext, useEffect, useState } from 'react';
import PaginationGroup from '../../../shared_components/PaginationGroup/PaginationGroup';
import Spinner from '../../../shared_components/Spinner/Spinner';
import PluginAssetService from '../../../services/PluginAssetService';
import UserData from '../../../services/UserData';
import InfoPage from '../../InfoPage/InfoPage';
import { useNavigate } from 'react-router-dom';
import ListHeadingWithSearch from '../../../shared_components/ListHeadingWithSearch/ListHeadingWithSearch';
import Utils from '../../../utils/Utils';
import StyledButton from '../../../shared_components/StyledButton/StyledButton';

const ViewPlugins = ({ updateView, activePage }) => {
  const navigate = useNavigate();

  const userData = useContext(UserData);
  const [loading, updateLoading] = useState(true);
  const [plugins, updatePlugins] = useState([]);
  const [maxPages, updateMaxPages] = useState(1);
  const [error, updateError] = useState(false);
  const [errorMessage, updateErrorMessage] = useState({});
  const [searchText, updateSearchText] = useState('');

  const createNewPlugin = () => {
    updateView("new");
  }

  const updatePageClicked = (page) => {
    navigate(`/plugins/${page}`);
    updateLoading(true);
  }

  const headingData = {
    name: "Name",
    pluginType: "Plugin Type",
    lastUpdateBy: "Last updated by"
  }


  const assetItem = (plugin) => {
    return (
      <AssetItem 
          key={plugin.assetKey}
          data={plugin}
          referrer={`/plugins/${activePage}`} 
          link={`/plugins/asset/interactive/${plugin.assetKey}`}
          assetType="interactive"
      />
    )
  }

  const getDivList = (
    <ItemList>
      <AssetItem
        data={headingData}
        heading={true}
      />
      <div>
        {plugins.map(plugin => assetItem(plugin))}
      </div>
    </ItemList>
  );

  const getListView = () => {
    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return <InfoPage reason={errorMessage.reason} description={errorMessage.description} />
    }
    return getDivList;
  }

  const getAllAssets = useCallback(() => {
    PluginAssetService.getInteractivePlugins(userData, activePage).then(data => {
      updatePlugins(data.content);
      updateLoading(false);
      updateMaxPages(data.totalPages);
    }).catch(error => {
      updateLoading(false);
      updateError(true);
      updateErrorMessage({
        reason: "Unable to fetch plugin list",
        description: error.message
      })
    });
  }, [activePage, userData]);

  const getFoundAssetsFromSearch = useCallback(() => {
    PluginAssetService.searchAsset(userData, activePage, 'interactive', searchText).then(data => {      
      updatePlugins(data.content);
      updateLoading(false);
      updateMaxPages(data.totalPages);
    }).catch(error => {
      updateLoading(false);
      updateError(true);
      updateErrorMessage({
        reason: "Unable to fetch plugin list",
        description: error.message
      })
    });
  }, [activePage, userData, searchText]);

  useEffect(() => {    
    if (Utils.isEmpty(searchText)) {
      getAllAssets();
    } else {
      getFoundAssetsFromSearch();
    }
  }, [searchText, activePage, getAllAssets, getFoundAssetsFromSearch]);
  

  const searchAction = (searchText) => {
    updateSearchText(searchText);
    updateLoading(true);
    navigate(`/plugins/1`);
  }

  const pluginListView = getListView();

  const getNewButtonView = () => {
    if (Utils.userIsViewOnly(userData)) {
      return null;
    }

    return <StyledButton clickEvent={createNewPlugin}>NEW PLUGIN</StyledButton>;
  }

  const getUserViewClass = () => {
    if (Utils.userIsViewOnly(userData)) {
      return PluginsStyle.viewer_view;
    }
    return PluginsStyle.creator_view;
  }

  return (
    <div className={`${PluginsStyle.plugins} ${getUserViewClass()}`}>
      {getNewButtonView()}
      <div className={PluginsStyle.list}>
        <ListHeadingWithSearch searchAction={searchAction}>PLUGINS LIST</ListHeadingWithSearch>
        {pluginListView}
        <PaginationGroup activePage={activePage} numberOfPages={maxPages} updatePageClicked={updatePageClicked}/>
      </div>
    </div>
  )
}

export default ViewPlugins;