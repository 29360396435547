import style from './NotFound.module.css';

const NotFound = () => {
	return (
		<div className={style.notfound}>
			<div className={style.notfounddiv}>
				<div className={style.notfound404}>404</div>
				<div className={style.notfoundpage}>Page not found</div>
				<div className={style.notfoundresource}>
					The resource requested could not be found on this server!
				</div>
			</div>
		</div>
	);
};

export default NotFound;
