import moment from "moment";
import Utils from "../../../../../utils/Utils";
import style from "./VersionHistoryItem.module.css";

const VersionHistoryItem = ({versionHistoryItem, heading}) => {
    const headingStyle = heading ? style.heading : null;
    const mainCommit = Utils.isNull(versionHistoryItem.mainCommitReference) ?
        "" : versionHistoryItem.mainCommitReference;

    const getDate = () => {
        if (heading) {
            return versionHistoryItem.created;
        }
        return moment(versionHistoryItem.created).format("DD-MM-YYYY HH:mm");
    }

    return (
        <div className={`${style.main} ${headingStyle}`}>
            <div>{getDate()}</div>
            <div>{versionHistoryItem.commit}</div>
            <div>{mainCommit}</div>
            <div>{versionHistoryItem.version}</div>
            <div>{versionHistoryItem.reason}</div>
        </div>
    )
}

export default VersionHistoryItem;