import style from "./AIMarkingPartition.module.css";

const AIMarkingPartition = ({children}) => {
    return (
        <div className={style.main}>
            {children}
        </div>
    )
}

export default AIMarkingPartition;