import { useContext, useState } from 'react';
import LoginStyle from './Login.module.css';
import UserData from '../services/UserData';
import UserService from '../services/UserService';
import Spinner from '../shared_components/Spinner/Spinner';
import ENV from '../services/Environment';

const Login = ({ setAuthorized }) => {
  const userData = useContext(UserData);
  const [username, setUsername] = useState(userData.username);
  const [password, setPassword] = useState('');
  const [postError, setPostError] = useState(userData.postError);
  const [loading, updateLoading] = useState(false);

  const loginUser = (event) => {
    event.stopPropagation();
    if (!validInput(username, password)) {
      setPostError("Username or Password cannot be null.");
      return;
    }
    UserService.loginUser(username.trim(), password.trim(), false, userData).then(data => {
      setAuthorized(true);
    }).catch(error => {
      showMeaningFullError();
    });
    updateLoading(true);
  }

  const showMeaningFullError = () => {
    setPassword('');
    if (userData.postError === null) {
      setPostError("Unexpected Error");
    } else {
      setPostError(userData.postError);
    }
    updateLoading(false);
  }

  const updateUsername = (event) => {
    userData.postError = null;
    setPostError(userData.postError);
    setUsername(event.target.value);
  }

  const updatePassword = (event) => {
    userData.postError = null;
    setPostError(userData.postError);
    setPassword(event.target.value);
  }

  const validInput = (username, password) => {
    if (username.length === 0 || password.length === 0) {
      return false;
    }
    return true;
  }

  const submitOrShowLoadingView = loading ? <Spinner /> : <button onClick={loginUser}>LOGIN</button>;

  const checkSubmit = (event) => {
    if (event.keyCode === 13) {
      loginUser(event);
    }
  }

  return (
    <div className={LoginStyle.Login}>
      <div className={LoginStyle.PasswordDiv}>
        <div className={LoginStyle.passwordDivLine}>
          <img src={ENV.loginAppLogo} alt={`${ENV.appIconName} logo`} />
        </div>
        <div className={LoginStyle.passwordDivLine}>
          <input type="text" placeholder='Username' value={username} onChange={updateUsername} onKeyUp={checkSubmit}/>
        </div>
        <div className={LoginStyle.passwordDivLine}>
          <input type="password" placeholder='Password' value={password} onChange={updatePassword} onKeyUp={checkSubmit}/>
        </div>        
        <div className={LoginStyle.passwordDivLine}>
          {submitOrShowLoadingView}
        </div>
        <div className={LoginStyle.passwordDivLine}>
          <span>{postError}</span>
        </div>
      </div>
    </div>
  )
}

export default Login;
