import AuditLogsBody from "./AuditLogsBody/AudiLogsBody";
import AuditLogsHeader from "./AuditLogsHeader/AuditLogsHeader";
import style from "./AuditLogs.module.css";
import PaginationGroup from "../../../../shared_components/PaginationGroup/PaginationGroup";
import { useContext, useEffect, useState } from "react";
import Spinner from "../../../../shared_components/Spinner/Spinner";
import AuditLogsService from "../../../../services/AuditLogsService";
import UserData from "../../../../services/UserData";
import InfoPage from "../../../InfoPage/InfoPage";
import DownloadService from "../../../../services/DownloadService";

const AuditLogs = () => {
    const [activePage, setActivePage] = useState(1);
    const [maximumPages, setMaximumPages] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const userData = useContext(UserData);
    const [auditLogs, setAuditLogs] = useState([]);
    const [personSelected, setPersonSelected] = useState("");
    const [dateTimeBetween, setDateTimeBetween] = useState(null);
    const [filterByDateValue, setFilterByDateValue] = useState("");
    const [listOfUsersInLogs, setListOfUsersInLogs] = useState(["All Staffmembers"]);
    const [sortDirection, setSortDirection] = useState("DESC");
    const [downloadingLogs, setDownloadingLogs] = useState(false);

    const updatePageNumberClicked = (page) => {
        if (page !== activePage) {
            setActivePage(page);
            setLoading(true);
        }
    }

    const updateSearchText = (keyWord) => {
        if (searchText !== keyWord) {
            setSearchText(keyWord);
            setActivePage(1);
            setLoading(true);
        }
    }

    const updatePersonSelected = (person) => {
        if (person !== personSelected) {
            setPersonSelected(person);
            setActivePage(1);
            setLoading(true)
        }
    }

    const updateSetDateTimeChange = (updatedDate) => {
        setDateTimeBetween(updatedDate);
        setActivePage(1);
        setLoading(true);
    }

    useEffect(() => {
        AuditLogsService.getAuditLogs(
            userData,
            activePage,
            searchText,
            personSelected,
            filterByDateValue,
            dateTimeBetween,
            sortDirection
        ).then(data => {
            setAuditLogs(data.content);
            setMaximumPages(data.totalPages);
            setErrorMessage("");
            setLoading(false);
        }).catch(error => {
            setErrorMessage(error.message);
        })
    }, [searchText, activePage, personSelected, filterByDateValue, dateTimeBetween, userData, sortDirection]);

    useEffect(() => {
        AuditLogsService.getUsernames(userData).then(data => {
            setListOfUsersInLogs(["All Staffmembers", ...data]);
        }).catch(error => {
            console.error(`Error fetching username ${error.message}`);
        });

        AuditLogsService.getDatesInBetween(userData).then(data => {
            const from = new Date(data.from).toISOString();
            const to = new Date(data.to).toISOString();
            setDateTimeBetween({from: from, to: to});
        }).catch(error => {
            console.error(`Error fetching date in between ${error.message}`);
        });
    }, [userData]);

    const updateSortDirection = (sortDirection) => {
        setLoading(true);
        setActivePage(1);
        setSortDirection(sortDirection);
    }

    const getAuditLogBody = () => {
        return (
            <AuditLogsBody 
                auditLogs={auditLogs}
                setSortDirectionSelection={updateSortDirection}
                sortDirection={sortDirection} 
            />
        );
    }

    const downloadUserLogs = () => {
        setDownloadingLogs(true);
        DownloadService.downloadUserLogs(userData, searchText, personSelected, filterByDateValue, dateTimeBetween, sortDirection).then(() => {
        }).catch(error => {
            console.error(error.message);
        }).finally(() => {
            setDownloadingLogs(false);
        })
    }

    const getDisplay = () => {
        const logsView = loading ? <Spinner /> : getAuditLogBody();

        if (errorMessage !== "") {
            return <InfoPage reason="Audit logs error" description={errorMessage} />;
        }

        return (
            <div className={style.main}>
                <AuditLogsHeader
                    searchAction={updateSearchText}
                    updatePersonSelected={updatePersonSelected}
                    listOfStaffMembers={listOfUsersInLogs} 
                    dateTimeBetween={dateTimeBetween}
                    setDateTimeBetween={updateSetDateTimeChange}
                    setFilterByDateValue={setFilterByDateValue}
                    downloadUserLogs={downloadUserLogs}
                    downloadingLogs={downloadingLogs}
                />
                {logsView}
                <PaginationGroup activePage={activePage} numberOfPages={maximumPages} updatePageClicked={updatePageNumberClicked}/>
            </div>
        );
    }

    return (
        getDisplay()
    )
}

export default AuditLogs;