import ENV from './Environment';
import Requests from './Requests';
import LocalStorageService from './LocalStorageService';
import Utils from '../utils/Utils';

const baseUrl = `${ENV.base_url}/api/v1/token`

const checkTokenValidity = async(userData) => {
  const response = await Requests.getRequest(`${baseUrl}/check/expiry`, userData.jwtToken, userData, false);
  if (response.status === 403) {
    return false;
  }
  const data = await response.json();
  if (data.tokenStatus === "REFRESH") {
    return await refreshToken(userData, data);
  }
  return data;
}

const canRefreshToken = (userData) => {
  if (Utils.isNull(userData.lastActionTimeStamp)) {
    return false;
  }
  const now = new Date();
  const secondsDiff = (now - userData.lastActionTimeStamp) / 1000;
  // Refresh if the user interacted with the site in the last 10 minutes.
  return secondsDiff < 600;
}

const refreshToken = async(userData, responseData) => {
  if (!canRefreshToken(userData)) {
    return responseData;
  }
  const response = await Requests.postRequest(`${baseUrl}/refresh`, userData.jwtToken, { refreshToken: userData.jwtToken}, userData, false);
  if (response.status === 403) {
    return responseData;
  }
  const data = await response.json();
  userData.jwtToken = data.token;
  const localData = LocalStorageService.getEncryptedLocalStorageData();
  localData.token = data.token;
  LocalStorageService.saveEncryptedData(localData);
  return data;
}

const TokenService = {
  checkTokenValidity: checkTokenValidity,
  refreshToken: refreshToken
}

export default TokenService;
