import ENV from './Environment';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1/generator`;

const getVersionNumber = async(userData) => {
  const result = await Requests.getRequest(`${baseUrl}/info`, userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const checkJob = async (guid, jwtToken, assetType) => {
  const result = await makeGetRequest(guid, jwtToken, assetType);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const getError = (data) => {
  if (data.error === undefined || data.error === null) {
    return "An Unexpected error occurred.";
  }
  return data.error;
}

const startJob = async (guid, jwtToken) => {
  const result = await makePostRequest(guid, jwtToken);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(getError(data));
  }
  return data;
}


const makeGetRequest = async(guid, jwtToken, assetType) => {
  const assetTypeString = assetTypeEmpty(assetType) ? "" : `/${assetType}`;
  const checkUrl = `${baseUrl}/status/${guid}${assetTypeString}`;
  return await fetch(checkUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })
}

const assetTypeEmpty = (assetType) => {
  return (assetType === null || assetType === undefined);
}

const makePostRequest = async (guid, jwtToken) => {
  const uploadRequest = `${baseUrl}/start/${guid}`;
  return await fetch(uploadRequest, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })
}

const GeneratorService = {
  checkJob: checkJob,
  startJob: startJob,
  getVersionNumber: getVersionNumber
}

export default GeneratorService;