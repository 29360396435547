import Detail from '../Detail/Detail';
import GeneratingDetailsStyle from './GeneratingDetails.module.css';

const GeneratingDetails = ( { generatingLogs } ) => {

  const getUserMessage = () => {
    if (generatingLogs.length === 0) {
      return <Detail heading={false} value="Busy ..."/>
    }

    return generatingLogs.map((message, index) => <Detail key={message + index} value={message} heading={false} />)
  }

  const actionMessages = getUserMessage();
  const messageHeading = "SERVER LOGS";

  return (
    <div className={GeneratingDetailsStyle.file_list_container}>
      <Detail value={messageHeading} heading={true}/>
      <div className={GeneratingDetailsStyle.file_container}>{actionMessages}</div>
    </div>
  )
}

export default GeneratingDetails;