import style from "./AssetView.module.css";

const AssetView = ({ styleName, url, title }) => {
  

  const styleMap = new Map();
  styleMap.set("idea", style.idea);
  styleMap.set("ybl", style.ybl);
  styleMap.set("undp", style.fintech);

  const backgroundCSSStyle = styleMap.get(styleName);

  return (
    <iframe className={`${backgroundCSSStyle}`} title={title} src={url}/>
  )
}

export default AssetView;