import ViewPlugins from './ViewPlugins/ViewPlugins';
import { useNavigate, useParams } from 'react-router-dom';
import Utils from '../../utils/Utils';

const PluginHome = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  
  const activePage = Utils.isNull(page) ? 1 : page;

  const updateView = () => {
    navigate('/asset/interactive', { state: { referrer: `/plugins/${activePage}`}});
  }

  return (
    <ViewPlugins updateView={updateView} activePage={activePage} />
  )
}

export default PluginHome;
