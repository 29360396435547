import AIMarkingHeaderLine from "./AIMarkingHeaderLine/AIMarkingHeaderLine";
import style from "./AIMarkingHeaders.module.css";

const AIMarkingHearders = ({essay}) => {
    return (
        <div className={style.main}>
            <AIMarkingHeaderLine
                firstHeading="Key Concepts" 
                firstContent={essay.keyConcepts}
                secondHeading="Learner level"
                secondContent={essay.learnerLevel}
            />
            <AIMarkingHeaderLine 
                firstHeading="Word Range"
                firstContent={essay.wordRange}
                secondHeading="Rubrics"
                secondContent={essay.rubrics}
            />
        </div>
    )
}

export default AIMarkingHearders;