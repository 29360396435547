import moment from 'moment';
import ENV from './Environment';
import Utils from '../utils/Utils';

const baseUrl = `${ENV.base_url}/api/v1/download`;
const baseUrlAuditLogs = `${ENV.base_url}/api/v1/audit-logs`;

const downloadFile = async (guid, jwtToken, filename, downloadFilename, downloadType) => {
  const path = `${baseUrl}/${downloadType}/${guid}/${filename}`;
  return await download(path, jwtToken, downloadFilename);
}

const downloadSpreadsheet = async(guid, jwtToken, downloadFilename) => {
  const path = `${baseUrl}/spreadsheet/${guid}`;
  return await download(path, jwtToken, downloadFilename);
}

const downloadGlossarySpreadsheet = async (glossaryName, jwtToken, downloadFilename) => {
  const path = `${baseUrl}/glossary/${glossaryName}`;
  return await download(path, jwtToken, downloadFilename);
}

const downloadUserLogs = async (userData, searchText, personSelected, filterByDateValue, dateTimeBetween, sortDirection) => {
  const url = new URL(`${baseUrlAuditLogs}/download`);
  url.searchParams.append("searchText", Utils.sanitizeText(searchText));
  url.searchParams.append("personSelected", personSelected);
  url.searchParams.append("filterByDate", filterByDateValue);
  url.searchParams.append('sortDirection', sortDirection);
  if (!Utils.isNull(dateTimeBetween)) {
    url.searchParams.append("dateFrom", dateTimeBetween.from);
    url.searchParams.append("dateTo", dateTimeBetween.to);
  }
  const userDate = moment().format("YYYY-MM-DD_HH_mm_ss");
  return await download(url.toString(), userData.jwtToken, `logs_${userDate}`);
}


const download = async(path, jwtToken, downloadFilename) => {
  const result = await makeGetRequest(path, jwtToken);
  if (!result.ok) {
    throw new Error("Unable to download file");
  }
  const blob = await result.blob();
  const url = URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = downloadFilename;
  anchor.click();
  URL.revokeObjectURL(url);
  return blob;
}

const makeGetRequest = async(path, jwtToken) => {
  return await fetch(path, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })
}

const DownloadService = {
  downloadFile: downloadFile,
  downloadSpreadsheet,
  downloadGlossarySpreadsheet,
  downloadUserLogs
}

export default DownloadService;