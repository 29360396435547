import LoadingPulse from "../../../shared_components/LoadingPulse/LoadingPulse";
import Utils from "../../../utils/Utils";
import AssetDetailLine from "./AssetDetailLine/AssetDetailLine";
import style from './GeneratedAssetDetails.module.css';

const GeneratedAssetDetails = ({assetDetails}) => {

  const assetDetailLines = () => {
    return (
      <>
        <AssetDetailLine term={"Created:"} value={assetDetails.creationDate} isTime={true} />
        <AssetDetailLine term={"Updated:"} value={assetDetails.lastUpdated} isTime={true} />
        <AssetDetailLine term={"Last update by:"} value={assetDetails.lastUpdatedBy} />
        <AssetDetailLine 
          term={"Spreadsheet:"}
          value={assetDetails.spreadsheetName}
          isSpreadsheet={true}
          assetDetails={assetDetails}/>
      </>
    )
  }

  const assetDetailView = Utils.isNull(assetDetails) ? <LoadingPulse /> : assetDetailLines();

  return (
    <div className={style.main}>
      {assetDetailView}
    </div>
  )
}

export default GeneratedAssetDetails;
