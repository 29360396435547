import MainContainerStyle from './MainContainer.module.css';

const MainContainer = ({ children, showMenu }) => {

  const getMenuVisibility = () => {
    return showMenu ? MainContainerStyle.show : MainContainerStyle.hide;
  }
  
  const showOrHideMenu = getMenuVisibility();

  return (
    <div className={`${MainContainerStyle.container} ${showOrHideMenu}`}>
      {children.map(child => child)}
    </div>
  )
}

export default MainContainer;
