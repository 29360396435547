import HeaderStyle from './Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import StyledButton from '../shared_components/StyledButton/StyledButton';
import ENV from '../services/Environment';

const Header = ({ logOut, toggleMenu }) => {
  
  const updateToggle = () => {
    toggleMenu.updateShowMenu(old => !old);
  }

  const getMenuIcon = () => {
    return toggleMenu.showMenu ? faXmark : faBars;
  }

  const menuIcon = getMenuIcon();

  return (
    <div className={HeaderStyle.header}>
      <div className={HeaderStyle.menu}><FontAwesomeIcon icon={menuIcon} onClick={updateToggle}/></div>
      <span>{ENV.appName}</span>
      <StyledButton clickEvent={logOut}>SIGN OUT</StyledButton>
    </div>
  )
}

export default Header;