import ENV from "./Environment";
import CryptoJS from "crypto-js";

const encryptData = data => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), ENV.encryptionKey).toString();
}

const saveEncryptedData = data => {
  const securedData = encryptData(data);
  localStorage.setItem(ENV.storageKey, securedData);
}

const getEncryptedData = () => {
  const dataUser = localStorage.getItem(ENV.storageKey);
  if (dataUser === null) {
    return null;
  }
  const bytes = CryptoJS.AES.decrypt(dataUser, ENV.encryptionKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

const getEncryptedLocalStorageData = () => {
  try {
    return getEncryptedData();
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

const LocalStorageService = {
  saveEncryptedData: saveEncryptedData,
  getEncryptedLocalStorageData: getEncryptedLocalStorageData
}

export default LocalStorageService;