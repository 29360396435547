import ENV from './Environment';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1`


const getInteractivePlugins = async(userData, page) => {
  const response = await Requests.getRequest(`${baseUrl}/interactive/list?page=${page - 1}`, userData.jwtToken, userData);  
  const data = await response.clone().json();
  if (!response.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const getAudioPlugins = async(userData, page) => {
  const response = await Requests.getRequest(`${baseUrl}/audio/list?page=${page - 1}`, userData.jwtToken, userData);
  const data = await response.clone().json();
  if (!response.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const previewAsset = async(userData, assetKey, filename, assetType) => {
  const url = `${baseUrl}/preview/html5/${assetKey}/${filename}/${assetType}`;
  const response = await Requests.getRequest(url, userData.jwtToken, userData);
  const data = await response.clone().json();
  if (!response.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const searchAsset = async(userData, page, type, searchText) => {
  const url = `${baseUrl}/search/${type}?page=${page - 1}&searchText=${searchText}`;
  const response = await Requests.getRequest(`${url}`, userData.jwtToken, userData);
  const data = await response.clone().json();
  if (!response.ok) {
    throw new Error(getError(data));
  }
  return data;
}

const deletePreview = async(userData, filename) => {
  const url = `${baseUrl}/preview/html5/${filename}`;
  const response = await Requests.deleteRequest(url, userData);
  const data = await response.clone().json();
  if (!response.ok) {
    throw new Error(getError(data));
  }
  return data;
}


const getError = (data) => {
  if (data.error === undefined || data.error === null) {
    return "Unexpected error";
  }
  return data.error;
}

const PluginAssetService = {
  getInteractivePlugins,
  getAudioPlugins,
  previewAsset,
  searchAsset,
  deletePreview
}

export default PluginAssetService;
