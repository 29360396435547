import VersionControlLine from "./VersionControlLine/VersionControlLine";
import style from "./VersionControl.module.css";
import { useContext, useEffect, useState } from "react";
import UserData from "../../../../services/UserData";
import Spinner from "../../../../shared_components/Spinner/Spinner";
import VersionControlService from "../../../../services/VersionControlService";
import Utils from "../../../../utils/Utils";
import InfoPage from "../../../InfoPage/InfoPage";
import CustomToast from "../../../../shared_components/CustomToast/CustomToast";

const VersionControl = () => {
    const userData = useContext(UserData);
    const [majorVersion, setMajorVersion] = useState(0);
    const [minorVersion, setMinorVersion] = useState(0);
    const [patchVersion, setPatchVersion] = useState(0);
    const [busy, setBusy] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        VersionControlService.getVersionInformation(userData).then(data => {
            updateVersionInfo(data);
        }).catch(error => {
            setErrorMessage(error.message);
        }).finally(() => {
            setBusy(false);
        })
    }, [userData])

    const updateMajorOrMinorVersion = (updateData) => {
        setBusy(true);
        VersionControlService.updateMajorOrMinorVersion(userData, updateData).then(data => {
            updateVersionInfo(data);
        }).catch(error => {
            setErrorMessage(error.message);
        }).finally(() => {
            setBusy(false);
        })
    }

    const updateVersionInfo = (data) => {
        setMajorVersion(data.major);
        setMinorVersion(data.minor);
        setPatchVersion(data.patch);
    }

    const showUserInformation = (message) => {
        setShowToast(true);
        setToastMessage(message);
    }

    const showVersionLines = () => {
        if (busy) {
            return <Spinner />
        }

        if (!Utils.isNull(errorMessage)) {
            return <InfoPage reason="Error" description={errorMessage} />
        }

        return (
            <div>
                <div className={style.heading}>Version Details</div>
                <VersionControlLine
                    name="Major Version"
                    value={majorVersion}
                    buttonName="Change Major Version Number"
                    buttonAction={updateMajorOrMinorVersion}
                    showUserInformation={showUserInformation}
                />
                <VersionControlLine
                    name="Minor Version"
                    value={minorVersion}
                    buttonName="Change Minor Version Number"
                    buttonAction={updateMajorOrMinorVersion}
                    showUserInformation={showUserInformation}
                />
                <VersionControlLine name="Patch Version" value={patchVersion} />
                <CustomToast message={toastMessage} showToast={showToast} setShow={setShowToast} />
            </div>
        )
    }

    return (
        showVersionLines()        
    )
}

export default VersionControl;