import { useContext, useState } from "react";
import LoadingPulse from "../../../shared_components/LoadingPulse/LoadingPulse";
import SearchButton from "../../../shared_components/SearchButton/SearchButton";
import style from './PluginGlossaryMenu.module.css';
import Heading from "../../../shared_components/Heading/Heading";
import UserData from "../../../services/UserData";
import UserClickTracker from "../../../services/UserClickTracker";

const PluginGlossaryMenu = ({ plugins, updateActivePlugin, activeIndex }) => {
  const [searchText, setSearchText] = useState("");
  const userData = useContext(UserData);

  const preUpdateActivePlugin = (index) => {
    UserClickTracker.registerClick(userData);
    updateActivePlugin(index);
  }

  const getDivFromPlugin = (plugin, index) => {
    const activeClass = index === activeIndex ? "active" : "";
    return (
      <div
        key={`${plugin.name.replace(" ", "_")}_${index}`}
        onClick={() => preUpdateActivePlugin(index)}
        className={activeClass}
      >{plugin.name}</div>
    )
  }

  const getSearchFromSearchText = () => {
    const searchUpper = searchText.toUpperCase();
    const searchResult = [];

    plugins.forEach((plugin, index) => {
      if (plugin.name.toUpperCase().indexOf(searchUpper) !== -1) {
        searchResult.push(getDivFromPlugin(plugin, index));
      }
    });
    return searchResult;
  }

  const getSearchResult = () => {
    const searchResult = getSearchFromSearchText();

    if (searchResult.length === 0) {
      return <div>Search result empty.</div>
    }

    return searchResult;
  }

  const getPluginsListView = () => {
    if (plugins.length === 0) {
      return <LoadingPulse />
    }

    if (searchText.length !== 0) {
      return getSearchResult();
    }

    return plugins
      .map((plugin, index) => getDivFromPlugin(plugin, index));
  }

  const searchAction = (searchText) => {
    setSearchText(searchText);    
  }

  const pluginListMenuView = getPluginsListView();

  return (
    <div className={style.glossary_menu}>
      <Heading>Glossary</Heading>
      <SearchButton placeholder="Search" searchAction={searchAction}/>
      <div className={style.glossary_list_heading}>Plugin List</div>
      <div className={style.glossary_list}>
        {pluginListMenuView}
      </div>
    </div>
  )
}

export default PluginGlossaryMenu;