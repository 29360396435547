import { useParams } from "react-router-dom";
import Utils from "../../../../utils/Utils";
import { useContext, useEffect, useState } from "react";
import UserData from "../../../../services/UserData";
import UserService from "../../../../services/UserService";
import ListContainer from "../../../../shared_components/ListContainer/ListContainer";
import ListHeadingWithSearch from "../../../../shared_components/ListHeadingWithSearch/ListHeadingWithSearch";
import ItemList from "../../../../shared_components/ItemList/ItemList";
import UserItem from "./UserItem/UserItem";
import InfoPage from "../../../InfoPage/InfoPage";
import Spinner from "../../../../shared_components/Spinner/Spinner";
import PaginationGroup from "../../../../shared_components/PaginationGroup/PaginationGroup";

const UserManagement = () => {
    const { page } = useParams();
    const safePage = Utils.isNull(page) ? 1 : page;
    const [searchText, setSearchText] = useState("");
    const [userList, setUserList] = useState(null);
    const userData = useContext(UserData);
    const [error, setError] = useState(null);
    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(safePage);
    const [userListVersion, setUserListVersion] = useState(0);

    const searchUsers = (searchTextFromTextBox) => {
        setSearchText(searchTextFromTextBox);
        setActivePage(1);
    }

    const updateUserListVersion = () => {
        setUserListVersion(old => old + 1);
    }

    useEffect(() => {
        UserService.getUsers(userData, activePage, searchText).then(data => {
            setUserList(data.content);
            setPages(data.totalPages);
        }).catch(error => {
            setError(error.message);
        })
    }, [userData, activePage, searchText, userListVersion]);
    
    const updatePageClicked = (page) => {
        setActivePage(page);
    }

    const getHeadings = () => {
        return {
            username: "Username",
            role: "Role",
            active: "Status"
        }
    }

    const getList = () => {
        return userList
            .map(userItem => <UserItem key={userItem.id} userItem={userItem} updateList={updateUserListVersion} />);
    }

    const getUsersListView = () => {
        return (
          <ItemList>
            <UserItem 
              userItem={getHeadings()}
              heading={true}
            />
            <div>
              {getList()}
            </div>
          </ItemList>
        )
      }

    const getUsersView = () => {
        if (!Utils.isNull(error)) {
            return <InfoPage description={error} reason="Unable to retrieve users." />;
        }

        if (Utils.isNull(userList)) {
            return <Spinner />
        }
        
        return getUsersListView()
    }

    const getPagination = () => {
        if (pages === 1) {
            return null;
        }
        return <PaginationGroup activePage={activePage} numberOfPages={pages} updatePageClicked={updatePageClicked}/>
    }

    return (
        <ListContainer>
            <ListHeadingWithSearch
                searchAction={searchUsers}
            >
                Users
            </ListHeadingWithSearch>
            {getUsersView()}
            {getPagination()}
        </ListContainer>
    )
}

export default UserManagement;