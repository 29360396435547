import { useContext, useEffect, useState } from "react";
import AIEssayService from "../../../services/AIEssayService";
import UserData from "../../../services/UserData";
import Permissions from "../../../services/Permissions";
import StyledButton from "../../../shared_components/StyledButton/StyledButton";
import MainContainerWithSearchAndPagination from "../../../shared_components/MainContainerWithSearchAndPagination/MainContainerWithSearchAndPagination";
import Spinner from "../../../shared_components/Spinner/Spinner";
import InfoPage from "../../InfoPage/InfoPage";
import style from "./AIEssaysList.module.css";
import MarkingLine from "./MarkingLine/MarkingLine";

const AIEssaysList = ({setView}) => {
    const userData = useContext(UserData);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [error, setError] = useState("");
    const [essays, setEssays] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const canCreate = Permissions.isAnyOfTheRolesAllowed(userData, AIEssayService.createRoles);

    useEffect(() => {
        setLoading(true);
        AIEssayService.fetchEssays(userData, searchText, activePage).then(data => {
            setEssays(data.content);
            setNumberOfPages(data.totalPages);
        }).catch(error => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        })
    }, [activePage, searchText, userData]);

    const partitionStyle = canCreate ? style.has_button : style.no_button;

    const searchAction = (searchString) => {
        setSearchText(searchString)
    }

    const createNewEssay = () => {
        setView("newEssay");
    }

    const pageChanged = (page) => {
        setActivePage(page);
    }

    const getDisplay = () => {
        if (loading) {
            return <Spinner />
        }

        if (error !== "") {
            return <InfoPage title="Error" reason="Unable to load AI markings" description={error} />
        }
        

        return (
            <div>
                <MarkingLine heading={true} />
                {essays.map((essay, index) => <MarkingLine key={`${index}`} essay={essay}/>)}
            </div>
        )
    }


    return (
        <div className={`${style.main} ${partitionStyle}`}>
            {canCreate ? <StyledButton clickEvent={createNewEssay}>NEW ESSAY</StyledButton> : null}
            <MainContainerWithSearchAndPagination
                activePage={activePage}
                numberOfPages={numberOfPages}
                updatePageClicked={pageChanged}
                searchAction={searchAction}
                heading={"AI ESSAYS"}
            >
                {getDisplay()}
            </MainContainerWithSearchAndPagination>
        </div>
    )
}

export default AIEssaysList;