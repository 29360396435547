import Dropzone from 'react-dropzone';
import DropZoneStyle from './DropZoneDiv.module.css';
import CompleteImage from '../../../../assets/complete_img.png';
import Spinner from '../../../shared_components/Spinner/Spinner';
import Utils from '../../../utils/Utils';

const DropZoneDiv = ({ 
    uploadFileOrFilesToServer,
    uploadingInProgress,
    generating,
    downloadReady,
    failedJob,
    filesUploaded }) => {

  const uploadedFiles = (acceptedFiles) => {
    uploadFileOrFilesToServer(acceptedFiles);
  }

  const getDropMessage = () => {
    return Utils.isEmpty(filesUploaded) ?
      "Drag 'n' drop the spreadsheet here, or click to select the spreadsheet." :
      "Drag 'n' drop the new or files to update here, or click to select the files"
  }

  const dropMessage = getDropMessage();

  const getDropZone = () => {
    return (<Dropzone onDrop={acceptedFiles => uploadedFiles(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>{dropMessage}</p>
                  </div>
                </section>
              )}
            </Dropzone>);
  }

  const getViewToShow = () => {
    if (downloadReady) {
      return <img src={CompleteImage} alt="Complete icon" />
    }

    if (failedJob) {
      return <span>Failed</span>
    }

    return (uploadingInProgress || generating) ? <Spinner /> : getDropZone();
  }

  const view = getViewToShow();

  return (
    <div className={DropZoneStyle.drop_zone} >
      {view}
    </div>
  )
}

export default DropZoneDiv;