import { useState, useCallback, useContext } from 'react';
import ImageService from '../../../services/ImageService';
import UserData from '../../../services/UserData';

const useImageGeneration = () => {
	const [imageID, setImageID] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const userData = useContext(UserData);

	const generateImage = useCallback(
		async (options) => {
			try {
				setIsLoading(true);

				const initialResponse = await ImageService.generateImage(
					'',
					userData.jwtToken,
					userData,
					options
				);
				if (initialResponse?.imageID) {
					setImageID(initialResponse.imageID);
					return initialResponse;
				} else {
					throw new Error('Invalid response from generateImage API');
				}
			} catch (error) {
				setIsLoading(false);
				return { status: 'FAIL', error: error.message };
			}
		},
		[userData]
	);

	return { generateImage, imageID, isLoading, setIsLoading };
};

export default useImageGeneration;
