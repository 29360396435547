import WhiteMainBackgroundStyle from './SubMenuWhiteBackground.module.css';

const SubMenuWhiteBackground = ({children}) => {
  return (
    <div className={WhiteMainBackgroundStyle.main}>
      {children}
    </div>
  )
}

export default SubMenuWhiteBackground;