import Utils from "../utils/Utils";

const isAdmin = (userData) => {
    if (Utils.isNull(userData.roles)) {
        return false;
    }
    return userData.roles.includes("admin");
}

const displayIfAdmin = (userData, view) => {
    return isAdmin(userData) ? view : null;
}

const isAllowed = (userData, role) => {
    if (Utils.isNull(userData.roles)) {
        return false;
    }
    return isAdmin(userData) || userData.roles.includes(role);
}

const isAnyOfTheRolesAllowed = (userData, roles) => {
    if (Utils.isNull(userData.roles) || Utils.isNull(roles)) {
        return false;
    }
    const searchMap = new Map();
    userData.roles.forEach(role => searchMap.set(role, true));
    return isAdmin(userData) || roles.find(role => searchMap.has(role));
}

const displayIfAnyOfTheRolesAreAllowed = (userData, roles, view) => {
    return isAnyOfTheRolesAllowed(userData, roles) ? view : null;
}

const displayIfAllowed = (userData, role, view) => {
    return isAllowed(userData, role) ? view : null;
}

const Permissions = {
    isAdmin,
    displayIfAdmin,
    isAllowed,
    displayIfAllowed,
    isAnyOfTheRolesAllowed,
    displayIfAnyOfTheRolesAreAllowed
}

export default Permissions;
