import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../../../../utils/Utils";
import style from "./AccessHistoryItem.module.css";
import { faCircleXmark, faPlugCircleCheck, faPlugCircleXmark } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AccessHistoryItem = ({ accessHistoryItem: item, heading}) => {

  const getStyleBasedOnHeading = (styleIfHeading, styleIfNot) => {
    const isHeading = Utils.isNull(heading) ? false : heading;

    return isHeading ? styleIfHeading : styleIfNot;
  }

  const getRelativeTime = (time) => {
    if (!Utils.isNull(heading) && heading) {
      return time;
    }

    return (
      <OverlayTrigger placement="left" overlay={<Tooltip id={time}>{moment(time).format("MMM Do YY, h:mm:ss a")}</Tooltip>}>
        <span>{moment(time).fromNow()}</span>
      </OverlayTrigger>
    )
  }

  const getIconWithOverlay = (fontIcon, message) => {
    return (
      <OverlayTrigger placement="left" overlay={<Tooltip>{message}</Tooltip>}>
        {fontIcon}
      </OverlayTrigger>
    )
  }

  const getIconForStatus = (status) => {
    if (!Utils.isNull(heading) && heading) {
      return status;
    }

    if (status === "SUCCESS") {
      return getIconWithOverlay(<FontAwesomeIcon className={style.success} icon={faPlugCircleCheck} />, "login");
    }

    if (status === "LOGOUT") {
      return getIconWithOverlay(<FontAwesomeIcon className={style.warn} icon={faPlugCircleXmark} />, "logout");
    }

    return <FontAwesomeIcon className={style.fail} icon={faCircleXmark} />
  }

  const redactIPAddress = (ip) => {
    if (!Utils.isNull(ip) && heading) {
      return ip;
    }

    return "[redacted]";
  }

  const headingClass = getStyleBasedOnHeading(style.heading, null);
  const centeredClass = getStyleBasedOnHeading(null, style.centered);

  return (
    <div className={`${style.history_container} ${headingClass}`}>
      <span className={centeredClass}>{getIconForStatus(item.status)}</span>
      <span>{item.username}</span>
      <span>{item.reason}</span>
      <span>{redactIPAddress(item.ip)}</span>
      <span>{getRelativeTime(item.localDateTime)}</span>
    </div>
  )
}

export default AccessHistoryItem;