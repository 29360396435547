import { useState } from "react";
import StyledButton from "../../../../../shared_components/StyledButton/StyledButton";
import Utils from "../../../../../utils/Utils";
import style from "./VersionControlLine.module.css";

const VersionControlLine = ({name, value, buttonName, buttonAction, showUserInformation}) => {
    const [showMessageDiv, setShowMessageDiv] = useState(false);
    const [versionNumber, setVersionNumber] = useState(value);
    const [versionMessage, setVersionMessage] = useState("");

    const showIncrementOption = () => {
        setShowMessageDiv(old => !old);
    }

    const getButton = () => {
        if (Utils.isNull(buttonName)) {
            return null;
        }
        return <StyledButton clickEvent={showIncrementOption}>{buttonName}</StyledButton>
    }

    const updateVersion = () => {
        if (Utils.isEmpty(versionMessage)) {
            showUserInformation("Version info message cannot be empty.");
            return;
        }
        if (name.includes("Major")) {
            buttonAction({major: versionNumber, majorMessage: versionMessage});
            return;
        }
        buttonAction({minor: versionNumber, minorMessage: versionMessage});
    }

    const updateVersionMessage = (ev) => {
        const val = ev.target.value;
        setVersionMessage(val);
    }

    const getMessageLine = () => {
        if (Utils.isNull(buttonName) || !showMessageDiv) {
            return null;
        }

        return (
            <div className={style.message_line}>
                <div className={style.text_line}><input type="text" placeholder="Version Message" onChange={updateVersionMessage} value={versionMessage}/></div>
                <div><StyledButton clickEvent={updateVersion}>SAVE</StyledButton></div>
            </div>
        )
    }

    const isDisabled = () => {
        return Utils.isNull(buttonName);
    }

    const changeVersionNumber = (event) => {
        const val = event.target.value;
        setVersionNumber(+val);
    }

    return (
        <div className={style.main}>
            <div className={style.version_line}>
                <div>{name}</div>
                <div><input type="number" value={versionNumber} disabled={isDisabled()} onChange={changeVersionNumber} min={value} /></div>
                {getButton()}
            </div>
            {getMessageLine()}
        </div>
        
    )
}

export default VersionControlLine;