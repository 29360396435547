import { useContext } from "react";
import UserData from "../../services/UserData";
import Utils from "../../utils/Utils";
import Spinner from "../../shared_components/Spinner/Spinner";
import NotFound from "../NotFound/NotFound";
import SubMenuWhiteBackground from "../../shared_components/SubMenuWhiteBackground/SubMenuWhiteBackground";
import WhiteMainBackground from "../../shared_components/WhiteMainBackground/WhiteMainBackground";
import SubMenuAndMainContainer from "../../shared_components/SubMenuAndMainContainer/SubMenuAndMainContainer";
import UsersMenu from "./UsersMenu/UsersMenu";
import UsersMain from "./UsersMain/UsersMain";


const Users = () => {
  const userData = useContext(UserData);

  if (!userData.roles.includes("admin")) {
    return <NotFound />
  }

  const getUserView = () => {
    if (Utils.isNull(userData)) {
      return <Spinner />
    }

    return (
      <SubMenuAndMainContainer>
        <SubMenuWhiteBackground>
          <UsersMenu />
        </SubMenuWhiteBackground>
        <WhiteMainBackground>
          <UsersMain />
        </WhiteMainBackground>
      </SubMenuAndMainContainer>
    );
  }

  const showUsersView = getUserView();

  return (
    showUsersView
  )
}

export default Users;