import Utils from "../../../../utils/Utils";
import LoadingPulse from '../../../../shared_components/LoadingPulse/LoadingPulse';
import style from './SpreadsheetGuide.module.css';
import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useEffect, useState } from "react";

const SpreadsheetGuide = ({ links }) => {
  const [linksArray, setLinksArray] = useState(null);

  const getLinks = () => {
    if (Utils.isNull(linksArray)) {
      return <LoadingPulse />;
    }

    return linksArray;
  }

  useEffect(() => {
    if (Utils.isNull(links)) {
      return;
    }

    const sanitizedLinks = links
      .filter(link => !Utils.isNull(link))
      .map((link, index) => <a key={`heading_${index}`} href={link.link}>{link.name}</a>);

    setLinksArray(sanitizedLinks);
  }, [links]);

  const linkViews = getLinks();

  return (
    <div className={style.spreadsheet_main}>
      <Accordion>
        <AccordionItem>
          <AccordionHeader>Spreadsheet Guides</AccordionHeader>
          <AccordionBody>
            {linkViews}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default SpreadsheetGuide;