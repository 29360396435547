import DetailStyle from './Detail.module.css';

const Detail = ({ value, heading }) => {

  const allClasses = heading ? `${DetailStyle.detail} ${DetailStyle.heading}` : DetailStyle.detail;

  return (
    <div className={allClasses}>{value}</div>
  )
}

export default Detail;