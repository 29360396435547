import Utils from '../utils/Utils';
import ENV from './Environment';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1/asset`;

const getAssetDetail = async(guid, userData) => {
  const url = `${baseUrl}/info/${guid}`;
  const result = await Requests.getRequest(url, userData.jwtToken, userData);
  const data = await result.clone().json();
  if (!result.ok) {
    throw new Error(Utils.getUsefulError(data));
  }
  return data;
}

const AssetService = {
  getAssetDetail
}

export default AssetService;