import { Route, Routes } from "react-router-dom"
import AccessHistory from "./AccessHistory/AccessHistory";
import NotFound from "../../NotFound/NotFound";
import UserManagement from "./UserManagement/UsersManagement";
import UserRoles from "./UserRoles/UserRoles";
import AuditLogs from "./AuditLogs/AuditLogs";

const UsersMain = () => {
  return (
    <Routes>
      <Route path="access-history" Component={AccessHistory} />
      <Route path="access-history/:page" Component={AccessHistory} />
      <Route path="user-management" Component={UserManagement} />
      <Route path="user-roles" Component={UserRoles} />
      <Route path="audit-logs" Component={AuditLogs} />
      <Route path="*" Component={NotFound} />
    </Routes>
  )
}

export default UsersMain;