import style from './Heading.module.css';

const Heading = ({ children: heading }) => {
  return (
    <div className={style.main}>
      <div className={style.heading}>{heading}</div>
    </div>
  )
}

export default Heading;