import { useRef } from "react";
import Spinner from "../../../shared_components/Spinner/Spinner";
import Utils from "../../../utils/Utils";
import InfoPage from "../../InfoPage/InfoPage";
import style from './DocumentationMain.module.css';

const DocumentationMain = ({ generateSubMenu, spreadsheetHtml, errorMessage }) => {
  const mainRef = useRef(null);

  const getHtmlDisplayView = () => {
    if (!Utils.isNull(errorMessage)) {
      return <InfoPage reason="Failed to load documentation" description={errorMessage} />
    }

    if (Utils.isNull(spreadsheetHtml)) {
      return <Spinner />
    }

    const documentationView =  (
      <div className={style.document_main}>
        <div ref={mainRef} dangerouslySetInnerHTML={{ __html: spreadsheetHtml }}></div>
      </div>
    );

    // Adding timeout before updating menu to give the component time to render
    // the html before showing the submenu.
    setTimeout(() => {
      generateSubMenu(mainRef);
    }, 200);

    return documentationView;
  }

  const spreadsheetHtmlView = getHtmlDisplayView();

  return (
    spreadsheetHtmlView
  )
}

export default DocumentationMain;