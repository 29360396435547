import style from './StyledButton.module.css';

const StyledButton = ({ type, clickEvent, className, children, ...rest }) => {
	return (
		<button
			type={type}
			onClick={clickEvent}
			className={`${style.button} ${className}`}
			{...rest}>
			{children}
		</button>
	);
};

export default StyledButton;
