import { useContext } from "react"
import UserData from "../../services/UserData"
import Permissions from "../../services/Permissions";
import NotFound from "../NotFound/NotFound";
import SubMenuAndMainContainer from "../../shared_components/SubMenuAndMainContainer/SubMenuAndMainContainer";
import SubMenuWhiteBackground from "../../shared_components/SubMenuWhiteBackground/SubMenuWhiteBackground";
import WhiteMainBackground from "../../shared_components/WhiteMainBackground/WhiteMainBackground";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import SettingsMain from "./SettingsMain/SettingsMain";

const Settings = () => {
    const userData = useContext(UserData);

    if (!Permissions.isAdmin(userData)) {
        return <NotFound />
    }

    return (
        <SubMenuAndMainContainer>
            <SubMenuWhiteBackground>
                <SettingsMenu />
            </SubMenuWhiteBackground>
            <WhiteMainBackground>
                <SettingsMain />
            </WhiteMainBackground>
        </SubMenuAndMainContainer>
    )
}

export default Settings;