import ENV from './Environment';
import Requests from './Requests';

const baseUrl = `${ENV.base_url}/api/v1/stream-audio`;

const getStream = async(path, jwtToken, userData) => {
  const result = await Requests.getRequest(path, jwtToken, userData);
  if (!result.ok) {
    throw new Error("Unable to download file");
  }
  return await result.blob();
}

const getAudioUrl = (guid, filename) => {
  return `${baseUrl}/${guid}/${filename}`;
}

const getAudioBlob = async(guid, jwtToken, filename, userData) => {
  const url = getAudioUrl(guid, filename);
  return await getStream(url, jwtToken, userData);
}

const AudioService = {
  getAudioBlob: getAudioBlob
}

export default AudioService;