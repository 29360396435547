import ENV from '../../../services/Environment';
import style from './BackgroundButtons.module.css';

const BackgroundButtons = ({ changeBackground }) => {

  const getButtonView = () => {
    if (ENV.appIconName === "undp") {
      return null;     
    }

    return (
      <>
        <div onClick={ () => changeBackground('idea') } className={style.idea}></div>
        <div onClick={ () => changeBackground('ybl') } className={style.ybl}></div>
        <div onClick={ () => changeBackground('undp') } className={style.fintech}></div>
      </>
    )
  }

  const buttonView = getButtonView();

  return (    
    <div className={style.main}>
      {buttonView}
    </div>
  )
}

export default BackgroundButtons;