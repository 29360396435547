import WhiteMainBackground from "../../shared_components/WhiteMainBackground/WhiteMainBackground";
import DocumentationMenu from "./Menu/DocumentationMenu";
import style from './DocumentationHome.module.css';
import SubMenuWhiteBackground from "../../shared_components/SubMenuWhiteBackground/SubMenuWhiteBackground";
import DocumentationMain from './DocumentationMain/DocumentationMain';
import { useEffect, useState } from "react";
import HtmlLoader from "../../services/HtmlLoader";
import Utils from "../../utils/Utils";
import HtmlLinkExtrator from "../../services/HtmlLinkExtractor";
import ENV from "../../services/Environment";

const DocumentationHome = () => {
  const [links, setLinks] = useState(null);
  const [spreadsheetHtml, setSpreadsheetHtml] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);  

  const generateSubMenu = (htmlRef) => {
    if (Utils.isNull(htmlRef.current) || !Utils.isNull(links)) {
      return;
    }

    const extractedLinks = HtmlLinkExtrator.extractHRefLinks(htmlRef);
    setLinks(extractedLinks);
  }

  useEffect(() => {
    HtmlLoader.fetchHtmlDocument(`plugin_spreadsheet_guide_${ENV.appIconName}.htm`).then(html => {
      setSpreadsheetHtml(html);
    }).catch(error => {
      setErrorMessage(error.message);
    })
  }, [])
  
  return (
    <div className={style.documentation_home}>
      <SubMenuWhiteBackground >
        <DocumentationMenu links={links} />
      </SubMenuWhiteBackground>
      <WhiteMainBackground>
        <DocumentationMain 
          generateSubMenu={generateSubMenu}
          spreadsheetHtml={spreadsheetHtml}
          errorMessage={errorMessage}
        />
      </WhiteMainBackground>
    </div>
  )
}

export default DocumentationHome;