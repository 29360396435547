const ENV = {
	base_url: process.env.REACT_APP_BASE_URL,
	encryptionKey: process.env.REACT_APP_ENCRYPTION_KEY,
	storageKey: 'user_data',
	appLogo: require(`../../assets/${process.env.REACT_APP_LOGO_NAME}`),
	appName: process.env.REACT_APP_NAME,
	appIconName: process.env.REACT_APP_ICON_NAME,
	loginAppLogo: require(`../../assets/${process.env.REACT_APP_LOGIN_LOGO}`),
	unsplashedKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
};

export default ENV;
