import LoadingPulseStyle from './LoadingPulse.module.css';

const LoadingPulse = () => {
  return (
    <div className={LoadingPulseStyle.container}>
      <div className={LoadingPulseStyle.dot_pulse}></div>
    </div>
  )
}

export default LoadingPulse;