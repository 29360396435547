import { useContext, useEffect, useState } from "react";
import ListHeadingWithSearch from "../../../../shared_components/ListHeadingWithSearch/ListHeadingWithSearch";
import Utils from "../../../../utils/Utils";
import Spinner from "../../../../shared_components/Spinner/Spinner";
import ItemList from "../../../../shared_components/ItemList/ItemList";
import AccessHistoryItem from "./AccessHistoryItem/AccessHistoryItem";
import UserData from "../../../../services/UserData";
import InfoPage from "../../../InfoPage/InfoPage";
import PaginationGroup from "../../../../shared_components/PaginationGroup/PaginationGroup";
import { useParams } from "react-router-dom";
import AuthService from "../../../../services/AuthService";
import style from "./AccessHistory.module.css";

const AccessHistory = () => {
  const { page } = useParams();
  const safePage = Utils.isNull(page) ? 1 : page;
  const [searchText, setSearchText] = useState("");
  const [accessHistoryList, setAccessHistoryList] = useState(null);
  const userData = useContext(UserData);
  const [error, setError] = useState(null);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(safePage);

  const searchAccessHistory = (searchTextFromTextBox) => {
    setSearchText(searchTextFromTextBox);
    setActivePage(1);
  }

  useEffect(() => {
    AuthService.getAccessHistoryList(userData, activePage, searchText).then(data => {
      setAccessHistoryList(data.content);
      setPages(data.totalPages);
    }).catch(error => {
      setError(error.message);
    });
  }, [userData, searchText, activePage]);

  const getList = () => {
    return accessHistoryList.map(historyItem => <AccessHistoryItem key={historyItem.id} accessHistoryItem={historyItem} />);
  }

  const getHeadings = () => {
    return {
      username: "Username",
      status: "Status",
      reason: "Reason",
      ip: "IP",
      localDateTime: "Time",
      token: "ID"
    }
  }

  const updatePageClicked = (page) => {
    setActivePage(page);
  }

  const getPagination = () => {
    if (pages === 1) {
      return null;
    }
    return <PaginationGroup activePage={activePage} numberOfPages={pages} updatePageClicked={updatePageClicked}/>
  }

  const getAccessHistoryListView = () => {
    return (
      <ItemList>
        <AccessHistoryItem 
          accessHistoryItem={getHeadings()}
          heading={true}
        />
        <div>
          {getList()}
        </div>
      </ItemList>
    )
  }

  const getAccessHistoryView = () => {
    if (!Utils.isNull(error)) {
      return <InfoPage description={error} reason="Unable to retrieve access history." />;
    }

    if (Utils.isNull(accessHistoryList)) {
      return <Spinner />
    }
    
    return getAccessHistoryListView()
  }

  return (
    <div className={style.history_container}>
      <ListHeadingWithSearch
        searchAction={searchAccessHistory}
      >
        Access History
      </ListHeadingWithSearch>      
      {getAccessHistoryView()}
      {getPagination()}
    </div>
  )
}

export default AccessHistory;