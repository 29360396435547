import { useContext, useState } from 'react';
import style from './SpreadsheetLine.module.css';
import LoadingPulse from '../../../../../shared_components/LoadingPulse/LoadingPulse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import DownloadService from '../../../../../services/DownloadService';
import UserData from '../../../../../services/UserData';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Utils from '../../../../../utils/Utils';

const SpreadsheetLine = ({ assetDetails, spreadsheetName }) => {
  const userData = useContext(UserData);
  const { assetKey } = assetDetails;
  const [downloading, updateDownloading] = useState(false);

  const downloadSpreadsheet = () => {
    DownloadService.downloadSpreadsheet(assetKey, userData.jwtToken, spreadsheetName).then(data => {
      updateDownloading(false);
    }).catch(error => {
      updateDownloading(false);
    })
    updateDownloading(true);
  }

  const getSpreadsheetView = () => {
    if (Utils.userIsViewOnly(userData)) {
      return <div>{spreadsheetName}</div>
    }

    return (
      <>
        <OverlayTrigger placement="left" overlay={<Tooltip id={spreadsheetName}>Download</Tooltip>}>
          <FontAwesomeIcon onClick={downloadSpreadsheet} icon={faDownload} />
        </OverlayTrigger>
        <div>{spreadsheetName}</div>
      </>
    )
  }

  const view = downloading ? <LoadingPulse /> : getSpreadsheetView();

  return (
    <div className={style.main}>
      {view}
    </div>
  )
}

export default SpreadsheetLine;