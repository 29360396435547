import { useNavigate, useParams } from "react-router-dom";
import WhiteMainBackgroundWithBackButton from "../../../shared_components/WhiteMainBackgroundWithBackButton/WhiteMainBackgroundWithBackButton";
import { useCallback, useContext, useEffect, useState } from "react";
import Spinner from "../../../shared_components/Spinner/Spinner";
import InfoPage from "../../InfoPage/InfoPage";
import AIEssayService from "../../../services/AIEssayService";
import UserData from "../../../services/UserData";
import Utils from "../../../utils/Utils";
import AIMarkingPartition from "./AIMarkingPartition/AIMarkingPartition";
import AIMarkingHearders from "./AIMarkingHeaders/AIMarkingHeaders";
import AIMarkingFooter from "./AIMarkingFooter/AIMarkingFooter";

const AIEssayMark = () => {
    const userData = useContext(UserData);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { guid } = useParams();
    const [essay, setEssay] = useState(null);

    const backButtonPressed = () => {
        navigate("/ai-essay");
    }

    const getMarkedEssay = useCallback(() => {
        AIEssayService.getMarking(userData, guid).then(data => {
            if (data.status === "FAIL") {
                throw new Error("Essay marking failed");
            }
            if (data.status === "SUCCESS") {
                setEssay(data);
            } else {
                setTimeout(() => {
                    getMarkedEssay();
                }, 300);
            }
        }).catch(error => {
            console.error(error);
            setError(error.message);
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }, [userData, guid])

    useEffect(() => {
        if (Utils.isNull(guid)) {
            return;
        }
        setLoading(true);
        getMarkedEssay();
    }, [guid, getMarkedEssay])

    const getDisplay = () => {
        if (error !== "") {
            return <InfoPage title="Error" reason="Failed reading AI marking" description={error}/>
        }

        if (loading || essay == null) {
            return <Spinner />
        }

        return (
            <AIMarkingPartition>
                <AIMarkingHearders essay={essay} />
                <textarea value={essay.essay} readOnly={true}/>
                <AIMarkingFooter essay={essay}/>
            </AIMarkingPartition>
        )
    }

    const getHeading = () => {
        if (loading) {
            return "Loading";
        }

        if (error !== "") {
            return "Error";
        }

        return Utils.isNull(essay) ? "Loading" : essay.topic;
    }

    return (
        <WhiteMainBackgroundWithBackButton heading={getHeading()} backButtonAction={backButtonPressed}>
            {getDisplay()}
        </WhiteMainBackgroundWithBackButton>
    )
}

export default AIEssayMark;