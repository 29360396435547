import ThemedBtnStyle from './ThemedBtn.module.css';

const SubmitBtn = ({ children, isDisabled, buttonAction }) => {
  const doAction = () => {
    buttonAction();
  }

  return (
    <button className={ThemedBtnStyle.themed_btn} disabled={isDisabled} onClick={doAction}>{children}</button>
  )
}

export default SubmitBtn;