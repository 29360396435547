import HomeStyle from './Home.module.css';
import Header from './Header/Header';
import Main from './Main/Main';
import { BrowserRouter } from 'react-router-dom';
import MainContainer from './MainContainer/MainContainer';
import Menu from './Menu/Menu';
import { useContext, useEffect, useState, useCallback } from 'react';
import UserData from './services/UserData';
import TokenService from './services/TokenService';
import Utils from './utils/Utils';

const Home = ({ logOut }) => {
  const [ showMenu, updateShowMenu ] = useState(true);
  const [ backEndVersion, updateBackEndVersion] = useState(null);
  const userData = useContext(UserData);
  const [numberOfFails, updateNumberOfFails] = useState(0);

  const getTokenDetails = useCallback((interval) => {
    TokenService.checkTokenValidity(userData).then(tokenCheckStatus => {
      if (tokenCheckStatus === false) {
        clearInterval(interval);
        logOut();
        return;
      }
      if (!Utils.isNull(backEndVersion) && backEndVersion !== tokenCheckStatus.appVersion) {
        updateBackEndVersion(tokenCheckStatus.appVersion);
        window.location.reload();
      } else {
        updateBackEndVersion(tokenCheckStatus.appVersion);
      }
    }).catch(error => {
      console.error(error.message);
      if (numberOfFails >= 5) {
        clearInterval(interval);
        logOut();
        return;
      }
      updateNumberOfFails(old => old + 1);
    })
  }, [logOut, userData, backEndVersion, numberOfFails]);

  useEffect(() => {
    const interval = setInterval(() => {
      getTokenDetails(interval);
    }, 60000) // 1 minute in milliseconds
    getTokenDetails(interval);
    return () => {
      clearInterval(interval);
    }
  }, [getTokenDetails]);

  const toggleMenu = {
    showMenu,
    updateShowMenu
  }

  return (
    <div className={HomeStyle.home_container}>
      <BrowserRouter>
        <Header logOut={logOut} toggleMenu={toggleMenu} />
        <MainContainer showMenu={showMenu}>
          <Menu backEndVersion={backEndVersion} />
          <Main /> 
        </MainContainer>
      </BrowserRouter>
    </div>
  )
}

export default Home;