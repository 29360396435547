import style from './GeneratedAssetList.module.css';
import { useState } from "react";
import GeneratedAssetView from "./GeneratedAssetView/GeneratedAssetView";

const GeneratedAssetList = ({url, title, list, assetKey}) => {
  const [audioPlaying, updateAudioPlaying] = useState(false);
  const [generatingPreview, updateGeneratingPreview] = useState(false);

  return (
    <div className={style.main}>
      <GeneratedAssetView heading={true}>{title}</GeneratedAssetView>
      <div className={style.list}>
        {
          list.map(item => {
            return (
              <GeneratedAssetView                 
                audioPlaying={audioPlaying}
                updateAudioPlaying={updateAudioPlaying}
                key={`gen-${item}`}
                url={url}
                assetKey={assetKey}
                generatingPreview={generatingPreview}
                updateGeneratingPreview={updateGeneratingPreview}
              >{item}</GeneratedAssetView>
            );
          })
        }
      </div>
    </div>
  );
}

export default GeneratedAssetList;