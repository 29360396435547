const fetchHtmlDocument = async (pageLink) => {
  return await loadHtml(pageLink);
}

const loadHtml = async (pageLink) => {
  const response = await fetch(pageLink);
  const textResponse = await response.clone().text();
  if (response.status !== 200) {
    throw new Error("An error occured while loading documentation file");
  }
  return textResponse;
}

const HtmlLoader = {
  fetchHtmlDocument
}

export default HtmlLoader;