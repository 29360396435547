import WhiteMainBackgroundStyle from './WhiteMainBackground.module.css';

const WhiteMainBackground = ({ children, className }) => {
	return (
		<div className={`${WhiteMainBackgroundStyle.main} ${className}`}>
			{children}
		</div>
	);
};

export default WhiteMainBackground;
