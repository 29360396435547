import { useContext, useEffect, useState, useCallback } from 'react';
import PluginsStyle from './ViewAudio.module.css';
import UserData from '../../../services/UserData';
import Spinner from '../../../shared_components/Spinner/Spinner';
import PluginAssetService from '../../../services/PluginAssetService';
import PaginationGroup from '../../../shared_components/PaginationGroup/PaginationGroup';
import AssetItem from '../../../shared_components/AssetItem/AssetItem';
import InfoPage from '../../InfoPage/InfoPage';
import { useNavigate } from 'react-router-dom';
import Utils from '../../../utils/Utils';
import ListHeadingWithSearch from '../../../shared_components/ListHeadingWithSearch/ListHeadingWithSearch';
import StyledButton from '../../../shared_components/StyledButton/StyledButton';
import ItemList from '../../../shared_components/ItemList/ItemList';

const ViewAudio = ({ updateView, activePage }) => {
	const navigate = useNavigate();

	const userData = useContext(UserData);
	const [loading, updateLoading] = useState(true);
	const [plugins, updatePlugins] = useState([]);
	const [maxPages, updateMaxPages] = useState(1);
	const [error, updateError] = useState(false);
	const [errorMessage, updateErrorMessage] = useState({});
	const [searchText, updateSearchText] = useState('');

	const updatePageClicked = (page) => {
		navigate(`/audio/${page}`);
		updateLoading(true);
	};

	const assetItem = (plugin) => {
		return (
			<AssetItem
				key={plugin.assetKey}
				data={plugin}
				referrer={`/audio/${activePage}`}
				link={`/audio/asset/audio/${plugin.assetKey}`}
				assetType='audio'
			/>
		);
	};

	const headingData = {
		name: 'Name',
		pluginType: 'Voice',
		lastUpdateBy: 'Last updated by',
	};

	const getDivList = (
		<ItemList>
			<AssetItem data={headingData} heading={true} />
			<div>{plugins.map((plugin) => assetItem(plugin))}</div>
		</ItemList>
	);

	const getListView = () => {
		if (loading) {
			return <Spinner />;
		}
		if (error) {
			return (
				<InfoPage
					reason={errorMessage.reason}
					description={errorMessage.description}
				/>
			);
		}
		return getDivList;
	};

	const getAllAssets = useCallback(() => {
		PluginAssetService.getAudioPlugins(userData, activePage)
			.then((data) => {
				updatePlugins(data.content);
				updateLoading(false);
				updateMaxPages(data.totalPages);
			})
			.catch((error) => {
				updateLoading(false);
				updateError(true);
				updateErrorMessage({
					reason: 'Unable to fetch audio list',
					description: error.message,
				});
			});
	}, [activePage, userData]);

	const getFoundAssetsFromSearch = useCallback(() => {
		PluginAssetService.searchAsset(userData, activePage, 'audio', searchText)
			.then((data) => {
				updatePlugins(data.content);
				updateLoading(false);
				updateMaxPages(data.totalPages);
			})
			.catch((error) => {
				updateLoading(false);
				updateError(true);
				updateErrorMessage({
					reason: 'Unable to fetch plugin list',
					description: error.message,
				});
			});
	}, [activePage, userData, searchText]);

	useEffect(() => {
		if (Utils.isEmpty(searchText)) {
			getAllAssets();
		} else {
			getFoundAssetsFromSearch();
		}
	}, [searchText, activePage, getAllAssets, getFoundAssetsFromSearch]);

	const pluginListView = getListView();

	const createNewAudio = () => {
		updateView('new');
	};

	const searchAction = (searchText) => {
		updateSearchText(searchText);
		updateLoading(true);
		navigate(`/audio/1`);
	};

	const getNewButtonView = () => {
		if (Utils.userIsViewOnly(userData)) {
			return null;
		}

		return <StyledButton clickEvent={createNewAudio}>NEW AUDIO</StyledButton>;
	};

	const getUserViewClass = () => {
		if (Utils.userIsViewOnly(userData)) {
			return PluginsStyle.viewer_view;
		}
		return PluginsStyle.creator_view;
	};

	return (
		<div className={`${PluginsStyle.plugins} ${getUserViewClass()}`}>
			{getNewButtonView()}
			<div className={PluginsStyle.list}>
				<ListHeadingWithSearch searchAction={searchAction}>
					AUDIO LIST
				</ListHeadingWithSearch>
				{pluginListView}
				<PaginationGroup
					activePage={activePage}
					numberOfPages={maxPages}
					updatePageClicked={updatePageClicked}
				/>
			</div>
		</div>
	);
};

export default ViewAudio;
