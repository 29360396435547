import ENV from './Environment';
import Requests from "./Requests";

const BASE_URL = `${ENV.base_url}/api/v1/roles`
const UPDATE_URL = `${ENV.base_url}/api/v1/cred/user/role`;

const getError = (data) => {
    if (data.error === undefined || data.error === null) {
      return "Unexpected error";
    }
    return data.error;
  }

const getRoles = async(userData) => {
    const url = `${BASE_URL}/all`;
    const response = await Requests.getRequest(url, userData.jwtToken, userData);
    const data = await response.clone().json();
    if (!response.ok) {
        throw new Error(getError(data));
    }
    return data;
}

const saveRolesForUser = async(userData, user) => {
  const userBody = {
    username: user.username,
    roles: user.roles.map(role => role.toUpperCase())
  }
  const response = await Requests.putRequest(UPDATE_URL, userData.jwtToken, userBody, userData)
  const data = await response.clone().json();
  if (!response.ok) {
    throw new Error(getError(data));
  }
  return data
}

const RolesService = {
    getRoles,
    saveRolesForUser
}

export default RolesService;