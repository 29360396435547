import { OverlayTrigger, Tooltip } from "react-bootstrap";
import style from "./EssaySelectEdit.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const EssaySelectEdit = ({question, helpMessage, formKey, formData, learnerGrades}) => {
    const [userlevel, setUserLevel] = useState(formData[formKey]);

    const updateSelect = (e) => {
        const value = e.target.value;
        formData[formKey] = value;
        setUserLevel(value);
    }

    return (
        <div className={style.main}>
            <div className={style}>{question}</div>
            <div className={style.select_input}>
                <select value={userlevel} onChange={updateSelect}>
                    {learnerGrades.map((learnerGrade, index) => <option key={`${learnerGrade}_${index}`} value={learnerGrade}>{learnerGrade}</option>)}
                </select>
            </div>
            <div className={style.tooltip}>
                <OverlayTrigger placement="left" overlay={<Tooltip id={question}>{helpMessage}</Tooltip>}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default EssaySelectEdit;