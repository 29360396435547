import style from "./AIMarkingLinePartition.module.css";

const AIMarkingLinePartition = ({heading, content}) => {
    return (
        <div className={style.main}>
            <div className={style.heading}>{heading}</div>
            <div>{content}</div>
        </div>
    )
}

export default AIMarkingLinePartition;