import { useState } from 'react';
import style from './ImageGeneratorForm.module.css';
import StyledButton from '../../../shared_components/StyledButton/StyledButton';

function ImageGeneratorForm({
	handleSubmit,
	textValue,
	handleChange,
	isInputEmpty,
	handleInputChange,
	textAreaIsDisabled,
	handleItemClick,
	isLoading,
}) {
	const [selectedOption, setSelectedOption] = useState('');

	const handleSelectChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		handleSubmit(event, textValue, selectedOption);
	};

	return (
		<div className={style.leftContainer}>
			<div className={style.leftblock}>
				<div className={style.styleBlock}>
					<h1>Style</h1>
					<p>
						Try out various styles to apply to your image and explore the
						possibilities
					</p>
					<div className={style.inputWithSelect}>
						<select
							value={selectedOption}
							onChange={handleSelectChange}
							className={style.select}>
							<option value=''>Select an option</option>
							<option value='Realistic'>Realistic</option>
							<option value='Sketch'>Sketch</option>
							<option value='Cartoon'>Cartoon</option>
							<option value='Flat Design'>Flat Design</option>
						</select>
					</div>
				</div>
				<div className={style.formPosition}>
					<h1>Prompt</h1>
					<p>
						What image do you want to see? Insert text in the box to generate
						image.
					</p>
					<div>
						<form onSubmit={handleFormSubmit} className={style.generator}>
							<textarea
								value={textValue}
								onChange={(event) => {
									handleChange(event);
									handleInputChange(event);
								}}
								placeholder='Type something...'
								rows={9}
								cols={50}
								className={style.textareaField}
							/>

							<StyledButton
								disabled={isInputEmpty || isLoading}
								className={`${style.styledButton} ${
									isInputEmpty || isLoading ? style.disabledButton : ''
								}`}>
								Generate Image
							</StyledButton>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImageGeneratorForm;
